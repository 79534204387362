import React from 'react';
import { InView } from 'react-intersection-observer';
import { FaRegCheckCircle } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/StorySection.css'; 
import 'animate.css';
import missionImage from '../assets/images/our-mission.png'

const Mission = () => (
  <div className="container my-5 py-5">
    <div className="row align-items-center">
      <div className="col-md-6">
        <InView triggerOnce>
          {({ inView, ref }) => (
            <img
              ref={ref}
              src={missionImage}
              alt="Mission Image"
              className={`img-fluid rounded ${inView ? 'animate__animated animate__fadeInLeft' : ''}`}
            />
          )}
        </InView>
      </div>
      <div className="col-md-6">
        <h2 className="d-flex justify-content-start our-mission">Our Mission</h2>
        <hr className="my-2" style={{ width: '60px', borderTop: '5px solid #007bff', borderRadius: '10px' }} />
        <p className='' style={{fontSize: '17px', textAlign: 'justify'}}>Our mission is to provide high-quality, reliable, and scalable products tailored to meet each client’s unique needs. We focus on leveraging cutting-edge technologies and building long-lasting partnerships. Your success is our priority.</p>
        <ul className='text-start list-unstyled'>
          <li style={{listStyle:'inherit'}}><FaRegCheckCircle className='pb-1' size={20} color="#002D65" />Delivering High-quality</li>
          <li style={{listStyle:'inherit'}}><FaRegCheckCircle className='pb-1' size={20} color="#002D65" />Innovative Solution</li>
          <li style={{listStyle:'inherit'}}><FaRegCheckCircle className='pb-1' size={20} color="#002D65" />User-Friendly Software</li>
        </ul>
      </div>
    </div>
  </div>
);

export default Mission;
