import React from 'react';
import {Link} from 'react-router-dom';

function AdminNavbar() {
  return (
    <nav className="navbar" style={{ backgroundColor: '#002D62' }}>
      <div className="container-fluid mx-3 mx-md-5 ">
        <Link className="navbar-brand" to="/">
          <Link
            to="/home"
            alt="Company-logo"
            className="rounded-circle img-fluid"
          />
           <p className='text-light fs-2 fw-bold d-flex align-items-center'><span>Net</span> Sol</p>

        </Link>
      </div>
    </nav>
  );
}

export default AdminNavbar;
