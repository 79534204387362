import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/css/Testimonial.css';
import testimonial1 from '../assets/images/testimonial1.jpg'
import testimonial2 from '../assets/images/testimonial2.jpg'
import testimonial3 from '../assets/images/testimonial3.jpg'


const testimonials = [
  {
    text: "The team provided exceptional IT solutions that perfectly fit our needs. Their support has been invaluable in keeping our operations running smoothly.",
    name: "Emar Sharp",
    title: "Social Advisor",
    image: testimonial1
  },
  {
    text: "Thanks, we’ve seen significant improvements in both security and efficiency. They deliver reliable IT services with a personal touch.",
    name: "SLiang Rubo",
    title: "IT Expert",
    image: testimonial2
  },
  {
    text: "We trust for all our IT needs, and they’ve consistently exceeded our expectations. Their expertise has made a real difference to our business.",
    name: "Kobie Fuller",
    title: "Product Designer",
    image: testimonial3
  },
  // Add more testimonials as needed
];

const Testimonial = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true, // Animation will only occur once
    });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    rtl: true, // This makes the carousel slide right to left
    pauseOnHover: true
  };

  return (
    <div className="container my-md-5 border mb-5 pb-5 shadow-lg" style={{ paddingTop: '20px'  , marginBottom:'3rem'}}>
      <h1 className='text-center testimonial-heading my-5' data-aos="fade-down">
        Our Testimonials
      </h1>
      <div className="testimonial-container">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-slide" data-aos="fade-up">
              <p className="testimonial-text">
                <i className="fas fa-quote-left quote-icon"></i>
                {testimonial.text}
              </p>
              <div className="testimonial-author">
                <img src={testimonial.image} alt={testimonial.name} className="author-image" />
                <div>
                  <h6>{testimonial.name}</h6>
                  <span>{testimonial.title}</span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonial;
