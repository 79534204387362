// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// function NotificationDetail({ notificationId, onBack }) {
//   const [notification, setNotification] = useState(null);

//   useEffect(() => {
//     // Fetch detailed notification data by id
//     axios.get(`http://localhost:8000/detailNotifcationStatus/${notificationId}`)
//       .then((response) => {
//         setNotification(response.data);
//       })
//       .catch((error) => {
//         console.error('Error fetching notification details:', error);
//       });
//   }, [notificationId]);

//   if (!notification) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <h2>Notification Details</h2>
//       <p><strong>Sender:</strong> {notification.email}</p>
//       <p><strong>Message:</strong> {notification.message}</p>
//       <p><strong>Time Sent:</strong> {new Date(notification.createdAt).toLocaleString()}</p>
//       <p><strong>Status:</strong> {notification.seen ? 'Seen' : 'Unseen'}</p>
//       <button onClick={onBack}>Back</button>
//     </div>
//   );
// }

// export default NotificationDetail;


import React, { useEffect, useState } from 'react';
import axios from 'axios';

function NotificationDetail({ notificationId, onBack }) {
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios.get(`https://techsol-seven.vercel.app/api/detailNotifcationStatus/${notificationId}`)
      .then((response) => {
        console.log('Notification data:', response.data); // Log data for debugging
        setNotification(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError('Error fetching notification details');
        setLoading(false);
        console.error('Error fetching notification details:', error);
      });
  }, [notificationId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!notification) {
    return <div>No notification found</div>;
  }

  // Check if dates are valid
  const createdAt = new Date(notification.createdAt);
  const seenAt = notification.seenAt ? new Date(notification.seenAt) : null;

  return (
    <div class="container mt-5">
  <div class="card shadow-lg border-0 p-4">
    {/* <!-- Top Row --> */}
    <div class="row align-items-center mb-4">
      <div class="col-4 text-start">
        <p class="mb-1"><strong>Sender:</strong> {notification.email} </p>
        
      </div>
      <div class="col-4 text-center">
        <p class="mb-1"><strong>Status:</strong> {notification.seen ? 'Seen' : 'Unseen'} </p>
      </div>
      <div class="col-4 text-end">
        <p class="mb-1"><strong>Time Sent:</strong> {createdAt.toLocaleString()} </p>
        {/* <p class="text-muted">{createdAt.toLocaleString()}</p> */}
      </div>
    </div>

    {/* <!-- Message Section --> */}
    <div class="row">
      <div class="col-12 text-start">
        <h5 class="mb-2"><strong>Message:</strong></h5>
        <p class="text-muted">{notification.message}</p>
      </div>
    </div>

    {/* <!-- Bottom Row --> */}
    <div class="row align-items-center mt-4">
      <div class="col-8 text-start">
        {notification.seen && (
          <p class="mb-0">
            <strong>Seen At:</strong> <span class="text-muted ">
              {seenAt ? seenAt.toLocaleString() : 'Not available'}
            </span>
          </p>
        )}
      </div>
      <div class="col-4 text-end">
        <button class="btn btn-primary px-4" onClick={onBack}>Back</button>
      </div>
    </div>
  </div>
</div>

  );
}

export default NotificationDetail;
