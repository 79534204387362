import React, {useEffect} from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaClock, FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp,} from "react-icons/fa";
import { Link } from "react-router-dom";
import "../css/Footer.css";


const Footer = () => {

  useEffect(() => {
    // Scroll to top on mount
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            {/* Information Column */}
            <div className="col-lg-4 col-md-6 mb-4">
              <h5>Information</h5>
              <hr />
              <p style={{ textAlign: "justify", fontSize: "16px", marginBottom: "-10px" }}>
                At Techsole, we empower businesses with innovative development
                solutions, ensuring exceptional quality and a commitment to
                exceeding customer expectations every step of the way.
              </p>
              <p style={{ whiteSpace: "nowrap", fontSize: "16px" ,marginTop: '10px', marginBottom: "-10px" }}>
                <FaMapMarkerAlt className="text-primary me-2" />
                10/C Main, Nawab Town Lahore
              </p>

              <p className="d-flex align-items-center" style={{ fontSize: "16px",marginTop: '10px',  marginBottom: "2px" }}>
                <a href="tel:+923471428593" className="text-decoration-none" style={{color:'#D3D3CA'}}>
                <FaPhoneAlt className="text-primary me-2" />
                +92 347 1428593
                </a>
              </p>
              <p className="d-flex align-items-center" style={{ fontSize: "16px" }} >
                <FaClock className="text-primary me-2" />
                Mon <span className="text-primary fw-bold mx-1">-</span> Friday: 9
                <span className="text-primary fw-bold mx-1">am</span> – 5
                <span className="text-primary fw-bold mx-1">pm</span>
              </p>
              <p style={{ whiteSpace: "nowrap", fontSize: "16px" }}>
                <span className="text-light fw-bold">
                  Saturday <span className="text-primary fw-bold">-</span>{" "}
                  Sunday:
                  <span className="text-danger fw-bold mx-1">CLOSED</span>
                </span>
              </p>
            </div>

            {/* Menu Column */}
            <div className="col-lg-2 col-md-6  mb-4">
              <h5>Menu</h5>
              <hr />
              <ul className="list-unstyled">
                <li>
                  <Link to="/services" style={{  fontSize: "16px" }}>Services</Link>
                </li>
                <li>
                  <Link to="/about" style={{  fontSize: "16px" }}>About Us</Link>
                </li>
                <li>
                  <Link to="/team" style={{  fontSize: "16px" }}>Our team</Link>
                </li>
                <li>
                  <Link to="/contact" style={{  fontSize: "16px" }}>Contact</Link>
                </li>
              </ul>
            </div>

            {/* Quick Links Column */}
            <div className="col-lg-2 col-md-6 mb-4">
              <h5>Quick Links</h5>
              <hr />
              <ul className="list-unstyled">
                <li>
                    <Link to="/about" style={{  fontSize: "16px" }}>How it works</Link>
                  </li>
                  <li>
                    <Link to="/team" style={{  fontSize: "16px" }}>Our team</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" style={{ fontSize: "16px" }}>Privacy Policy</Link>
                  </li>
              </ul>
            </div>

            {/* Newsletter Column */}
            <div className="col-lg-4 col-md-6 mb-4">
              <h5>Our Newsletters</h5>
              <hr />
              <p style={{ fontSize: "18px"}}>
                Add a newsletter to your widget area.
              </p>
              <Link to="/contact" className="text-decoration-none">
              <div className="input-group">
                <input
                  type="email"
                  className="form-control newsletter-input text-decoration-none shadow-none"
                  placeholder="Enter your e-mail"
                />
                <button className="btn btn-primary" type="button">
                  Subscribe
                </button>
              </div>
              </Link>
              <div className="social-icons d-flex justify-content-center mt-3">
                <ul className="list-unstyled d-flex">
                  <li>
                    <Link to="https://www.facebook.com/profile.php?id=61571234807734&mibextid=ZbWKwL" className="mx-1" target="_blank" rel="noopener noreferrer">
                      <FaFacebook className="text-white fs-3" />
                    </Link>
                  </li>
                  <li>
                     <Link to="https://www.instagram.com/techsol_technologies?igsh=OHZuNG51bWh3Mnlw" className="mx-1" target="_blank" rel="noopener noreferrer">
                      <FaInstagram className="text-white fs-3" />
                    </Link>
                  </li>
                  <li>
                     <Link to="https://www.linkedin.com/company/techsoltech/" className="mx-1" target="_blank" rel="noopener noreferrer">
                      <FaLinkedin className="text-white fs-3" />
                    </Link>
                  </li>
                  <li>
                     <Link to="tel:923471428593" className="mx-1" target="_blank" rel="noopener noreferrer">
                      <FaWhatsapp className="text-white fs-3" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="footer-bottom border-top border-white-50" style={{ maxHeight: "1px" , paddingTop:'10px'}}>
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-6 text-center text-md-start mb-md-0 ">
                      <p className="fs-6" > &copy; {new Date().getFullYear()} Techsol technologies. All rights reserve </p>
                    </div>
                    <div className="col-md-6 text-center text-md-start mb-md-0 d-flex justify-content-end">
                      <Link to="/privacy-policy" className="text-decoration-none mx-1 text-white"><span>Privacy Policy</span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
