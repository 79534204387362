import React, { useEffect } from "react";
import { FaShieldAlt, FaUser, FaUsers, FaLock } from "react-icons/fa";
import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../assets/css/PrivacyPolicy.css';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import Contactus from "../components/Contactus";

const PrivacyPolicy = () => {
  useEffect(() => {
    AOS.init({ duration: 1200, easing: 'ease-in-out', once: true });
  }, []);

  return (
    <>
      <div>
        <Navbar/>
      </div>

      <div className="services-header">
        <div className="header-content">
          <h1 className="header-title animate__animated animate__fadeInDown">Privacy Policy</h1>
          <div className="breadcrumb">
            <Link to="/" className="breadcrumb-link">Home</Link> 
            <span className="breadcrumb-separator"> &gt; </span>
            <span className="breadcrumb-current">Privacy policy</span>
          </div>
        </div>
      </div>

      <div className="container my-5 p-4 rounded">
        <h2 className="text-center mb-4" style={{ color: "#002D62" }}>Privacy Policy</h2>

        {/* Section: Introduction */}
        <div className="row mb-4" data-aos="fade-up">
          <div className="col">
            <div className="p-4 shadow-sm border-0 rounded-3 privacy-card">
              <h3 className="d-flex align-items-center">
                <span className="icon-circle me-2 bg-primary text-white d-flex align-items-center justify-content-center" style={{ height: '40px', width: '40px', borderRadius: '50%' }}>
                  <FaShieldAlt />
                </span>
                Introduction
              </h3>
              <p className="text-start" style={{fontSize: '18px'}}>
                Welcome to <span className="fw-bold" style={{color: '#002D65'}}>TechSoltech</span> We are committed to protecting your personal information and your right to privacy.
                This Privacy Policy explains how we collect, use, and safeguard your data when you visit our website or use our services.
              </p>
            </div>
          </div>
        </div>

        {/* Section: Information We Collect */}
        <div className="row mb-4" data-aos="fade-right">
          <div className="col">
            <div className="p-4 shadow-sm border-0 rounded-3 privacy-card">
              <h3 className="d-flex align-items-center">
                <span className="icon-circle me-2 bg-success text-white d-flex align-items-center justify-content-center" style={{ height: '40px', width: '40px', borderRadius: '50%' }}>
                  <FaUser />
                </span>
                Information We Collect
              </h3>
              <p className="text-start" style={{fontSize: '18px'}}>
                We may collect personal information that you provide to us, including your name, email address, phone number, and other contact
                details when you fill out forms on our website, subscribe to our newsletter, or engage with our services.
              </p>
              <ul className="list-unstyled" style={{fontSize: '18px'}}>
                <li className="text-start"><strong>Personal Information:</strong> Contact details, company information, etc.</li>
                <li className="text-start"><strong>Usage Data:</strong> IP address, browser type, and activity on our website.</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Section: How We Use Your Information */}
        <div className="row mb-4" data-aos="fade-left">
          <div className="col">
            <div className="p-4 shadow-sm border-0 rounded-3 privacy-card">
              <h3 className="d-flex align-items-center">
                <span className="icon-circle me-2 bg-warning text-white d-flex align-items-center justify-content-center" style={{ height: '40px', width: '40px', borderRadius: '50%' }}>
                  <FaUsers />
                </span>
                How We Use Your Information
              </h3>
              <p className="text-start" style={{fontSize: '18px'}}>
                We use the collected data to provide, improve, and maintain our services, respond to inquiries, manage user accounts, and provide
                personalized experiences. Additionally, data is used for analytical purposes to improve our offerings and ensure website security.
              </p>
            </div>
          </div>
        </div>

        {/* Section: Data Sharing & Disclosure */}
        <div className="row mb-4" data-aos="fade-up">
          <div className="col">
            <div className="p-4 shadow-sm border-0 rounded-3 privacy-card">
              <h3 className="d-flex align-items-center">
                <span className="icon-circle me-2 bg-danger text-white d-flex align-items-center justify-content-center" style={{ height: '40px', width: '40px', borderRadius: '50%' }}>
                  <FaLock />
                </span>
                Data Sharing & Disclosure
              </h3>
              <p className="text-start" style={{fontSize: '18px'}}>
                We do not sell or rent your personal information to third parties. We may share your data with trusted service providers who assist us
                in operating our website or conducting our business, as long as they agree to keep this information confidential.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Contactus/>
      </div>

      <div>
        <Footer/>
      </div>
    </>
  );
};

export default PrivacyPolicy;



