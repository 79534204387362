import "./App.css";
import Navbar from "./components/Navbar";
import LandingPage from "./components/Landingpage";
import WhoweAre from "./components/WhoweAre";
import FeaturesSection from "./components/FeaturesSection";
import Testimonial from "./components/Testimonial";
import CTASection from "./components/CTAsection";
import TeamSection from "./components/TeamSection";
import Footer from "./components/Footer";
import Contactus from "./components/Contactus";
import SeeMoreServices from "./pages/SeeMoreServices";
import AdminLogin from "./components/Login";
import AdminNavbar from "./components/AdminNavbar";
import Dashboard from "./components/Dashboard";
import Register from "./components/Register";
import PrivateRoute from "./components/PrivateRoute";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Blogs from "./pages/Blogs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AboutSection from "./components/AboutSection";
import ServiceDetail from "./pages/ServiceDetail";
import ContactTab from "./components/ContactTab";
import WebServicePage from "./serviceDetailPages/WebServicePage";
import LaravelServicePage from "./serviceDetailPages/LaravelServicePage";
import AppServicePage from "./serviceDetailPages/AppServicePage";
import AIServicePage from "./serviceDetailPages/AIServicePage";
import WordpressServicePage from "./serviceDetailPages/WordpressServicePage";
import UiUxServicePage from "./serviceDetailPages/UiUxServicePage";
import GraphicDesServicePage from "./serviceDetailPages/GraphicDesServicePage";
import SeoServicePage from "./serviceDetailPages/SeoServicePage";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App bg-white">
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route
            path="/"
            element={
              <>
                <Helmet>
                  <title>TechSol Technologies | Innovating the Future</title>
                  <meta
                    name="description"
                    content="Get in touch with TechSol Technologies for inquiries, support, or feedback."
                  />
                </Helmet>
                <Navbar />
                <LandingPage />
                <WhoweAre />
                <FeaturesSection />
                <CTASection />
                <Testimonial />
                {/* <Contactus /> */}

                <Footer />
              </>
            }
          />

          <Route path="/services" element={<SeeMoreServices />} />
          <Route path="/about" element={<AboutSection />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/service-detail" element={<ServiceDetail />} />
          <Route path="/team" element={<TeamSection />} />
          <Route path="/contact" element={<ContactTab />} />
          <Route path="/web-development" element={<WebServicePage />} />
          <Route path="/laravel" element={<LaravelServicePage />} />
          <Route path="/app-development" element={<AppServicePage />} />
          <Route path="/artificial-intelligence" element={<AIServicePage />} />
          <Route path="/wordpress" element={<WordpressServicePage />} />
          <Route path="/uiux" element={<UiUxServicePage />} />
          <Route path="/graphic-desiging" element={<GraphicDesServicePage />} />
          <Route path="/seo-optimization" element={<SeoServicePage />} />


          {/* Admin Routes */}
          <Route
            path="/techadmin"
            element={
              <>
                <AdminNavbar />
                <AdminLogin />
              </>
            }
          />
          <Route
            path="/techregister"
            element={
              <>
                <AdminNavbar />
                <Register />
              </>
            }
          />

          {/* Private Route - Protecting the Dashboard route */}
          <Route element={<PrivateRoute />}>
            <Route path="/techdashboard" element={<Dashboard />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
