import React, { useEffect } from 'react';
import '../assets/css/WhoweAre.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { GrVmMaintenance } from "react-icons/gr";
import { SiAntdesign } from "react-icons/si";
import { BiStreetView } from "react-icons/bi";
import { TfiSplitVAlt } from "react-icons/tfi";
import whowe from '../assets/images/who-we.avif'

const WhoweAre = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });

  }, []);

  return (
    <div className="container">
      <div className="row align-items-center mt-5">
        {/* Lottie Animation Section */}
        <div className="col-lg-6 col-md-6 order-2 order-md-1 pt-2 mt-sm-0 opt-sm-0 text-center" data-aos="fade-right">
  <img
    src={whowe}
    alt="who-we-are"
    className="img-fluid"
    style={{ maxWidth: '100%', height: 'auto' }}
  />
</div>


        {/* Text Section */}
        <div className="col-lg-6 col-md-6 col-12 order-1 order-md-2" data-aos="fade-left">
          <div className="section-title ml-lg-5">
            <h2 className="text-center whoweheading">Who we are</h2>
            <p className="font-weight-normal who-para " >
            We’re a passionate team of tech experts dedicated to delivering innovative software solutions. From web and app development to AI and design, we help businesses thrive in the digital age. With a focus on quality, creativity, and collaboration, we’re here to turn your ideas into impactful, real-world solutions.
            </p>

            {/* Icons Section */}
<div className="row">
  <div className="col-lg-6 col-md-6 col-sm-12 mt-4 pt-2" style={{cursor: 'pointer'}} data-aos="zoom-in">
    <div className="media align-items-center rounded shadow p-3">
      <TfiSplitVAlt className="h4 mb-0 text-custom" />
      <h6 className="ml-3 mb-0">
        <a href="#!" className="text-dark text-decoration-none">Responsive Design</a>
      </h6>
    </div>
  </div>
  
  <div className="col-lg-6 col-md-6 col-sm-12 mt-4 pt-2" style={{cursor: 'pointer'}} data-aos="zoom-in" data-aos-delay="100">
    <div className="media align-items-center rounded shadow p-3">
      <GrVmMaintenance className="h4 mb-0 text-custom" />
      <h6 className="ml-3 mb-0">
        <a href="#!" className="text-dark text-decoration-none">Maintenance</a>
      </h6>
    </div>
  </div>
  
  <div className="col-lg-6 col-md-6 col-sm-12 mt-4 pt-2" style={{cursor: 'pointer'}} data-aos="zoom-in" data-aos-delay="200">
    <div className="media align-items-center rounded shadow p-3">
      <BiStreetView className="h4 mb-0 text-custom" />
      <h6 className="ml-3 mb-0">
        <a href="#!" className="text-dark text-decoration-none">Support</a>
      </h6>
    </div>
  </div>
  
  <div className="col-lg-6 col-md-6 col-sm-12 mt-4 pt-2" style={{cursor: 'pointer'}} data-aos="zoom-in" data-aos-delay="300">
    <div className="media align-items-center rounded shadow p-3">
      <SiAntdesign className="h4 mb-0 text-custom" />
      <h6 className="ml-3 mb-0">
        <a href="#!" className="text-dark text-decoration-none">Development</a>
      </h6>
    </div>
  </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoweAre;
