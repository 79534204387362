import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../assets/css/CTASection.css';
// import letsget from '../assets/images/lets-get-img.gif';
import { Link } from 'react-router-dom';
import letsget from '../assets/images/output-onlinegiftools.gif';


const CTAsection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="technology-section d-flex align-items-center justify-content-center mt-5">
      <div className="container d-flex align-items-center">
        <div className="row w-100 align-items-center">
          <div className="col-md-6">
            {/* Circle background */}
            <div className="circle-background" data-aos="fade-right">
              <img className="img-fluid responsive-image" src={letsget} alt="Let's Get Started" />
            </div>
          </div>

          <div className="col-md-6 text-container" data-aos="fade-left">
          <h2 className="getstarted-title">Let's Get Started</h2>
            <p className="section-description text-dark">We turn ideas into reality with innovative tech solutions. Whether you need a website, app, or AI-driven product, we’re here to help. Get in touch now, and let's bring your vision to life!</p>
            <Link to="/contact"><button className="explore-btn" data-aos="fade-up">Explore Our Technology</button></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTAsection;
