import React, { useEffect } from "react";
import { FaCheckCircle, FaArrowRight } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/ServiceDetail.css"; // Custom CSS file for additional styling
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import imageAI from '../assets/images/service-AI.webp'

const ServiceDetail = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: "ease-in-out",
      once: true, // Animation only happens once
    });
  }, []);

  return (
    <>
      <div>
        <Navbar />
      </div>
      <div className="services-header" data-aos="fade-down">
        <div className="header-content">
          <h1 className="header-title">Services</h1>
          <div className="breadcrumb">
            <Link to="/" className="breadcrumb-link">
              Home
            </Link>
            <span className="breadcrumb-separator"> &gt; </span>
            <span className="breadcrumb-current">Services</span>
          </div>
        </div>
      </div>

      <div className="container my-5 service-detail">
        <div className="row">
          {/* Service List */}
          <div className="col-lg-4" data-aos="fade-right">
            <div className="service-list-container mt-md-4 p-4 rounded shadow">
            <ul className="list-unstyled">
                <li className="mb-2">
                  <Link to="/laravel" className="service-link active d-flex justify-content-between align-items-center">
                    LARAVEL <FaArrowRight />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/web-development" className="service-link d-flex justify-content-between align-items-center">
                    Web Development <FaArrowRight />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/wordpress" className="service-link d-flex justify-content-between align-items-center">
                    WORDPRESS <FaArrowRight />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/app-development" className="service-link d-flex justify-content-between align-items-center">
                    ANDROID & IOS <FaArrowRight />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/artificial-intelligence" className="service-link d-flex justify-content-between align-items-center">
                    ML & AI <FaArrowRight />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/uiux" className="service-link d-flex justify-content-between align-items-center">
                    UI / UX <FaArrowRight />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/seo-optimization" className="service-link d-flex justify-content-between align-items-center">
                    SEO Optimization <FaArrowRight />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/graphic-desiging" className="service-link d-flex justify-content-between align-items-center">
                    Graphic Desiging <FaArrowRight />
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Content Section */}
          <div className="col-lg-8" data-aos="fade-left">
            <div className="quality-section mt-5">
              <h3 className="section-title text-start">History Of Artificial Intelligence</h3>
              <p className="quality-description text-start mb-4">
              Machine Learning (ML) and Artificial Intelligence (AI) have roots in early computer science, with AI research dating back to the 1950s. Over the past few decades, advancements in data processing and computing power have driven significant progress in these fields.
              </p>
            </div>

            <div className="row align-items-center">
              <div className="col-md-7" data-aos="fade-up">
                <h2 className="section-title text-start">Scope Of Artificial Intelligence</h2>
                <p className="section-description text-start">
                ML and AI are transforming industries such as healthcare, finance, robotics, and automotive, providing intelligent solutions for automation, decision-making, and predictive analytics.
                </p>
                <div className="features d-flex flex-row">
                  <div className="d-flex flex-column">
                    <div className="feature-item my-2">
                      <FaCheckCircle className="feature-icon mx-1" /> Full-stack
                      web development
                    </div>
                    <div className="feature-item my-2">
                      <FaCheckCircle className="feature-icon mx-1" /> API
                      development
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="feature-item my-2">
                      <FaCheckCircle className="feature-icon mx-1" /> Real-time
                      applications
                    </div>
                    <div className="feature-item my-2">
                      <FaCheckCircle className="feature-icon mx-1" /> Scalability
                    </div>
                  </div>
                </div>
              </div>

              {/* Image Section */}
              <div className="col-md-5 text-center" data-aos="zoom-in">
                <div className="image-box">
                  <img
                    src={imageAI}
                    alt="Laravel Logo"
                    className="logo-image" style={{objectFit: 'cover'}}
                  />
                </div>
              </div>
            </div>

            <div className="quality-section mt-5" data-aos="fade-up">
              <h3 className="quality-title text-start">
                Quality Industrial Working
              </h3>
              <p className="quality-description text-start">
              High-quality AI and ML models require clean, accurate data and continuous model training to improve predictions. The industry focuses on building explainable AI systems and ensuring ethical practices in data collection and usage.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </>
  );
};

export default ServiceDetail;
