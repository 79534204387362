

import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../assets/css/TeamSection.css';
import t1saqibasghar from '../assets/images/t1-saqibasghar.png';
import t2hassnainaltaf1 from '../assets/images/Pi7_Passport_Photo.jpeg';
import t3moiz from '../assets/images/t3-moiz.png';
import t4abdullah from '../assets/images/t4-abdullah.png';
import t3zuhaab from '../assets/images/t-zuhaab.png'
import t6salman from '../assets/images/t6-salman.png'
import t7talha from '../assets/images/t7-talha.png'
import t8ahsan from '../assets/images/t8-ahsan.png'
import t9rao from '../assets/images/t9-rao.png';
import t10ali from '../assets/images/t10-ali.png';
import { Helmet } from 'react-helmet';

import { FaFacebookF, FaLinkedin , FaInstagram } from 'react-icons/fa';

import DedicatedTeam from './DedicatedTeam';
import Footer from './Footer';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

const teamMembers = [
  {
    name: 'Muhammad Saqib',
    role: 'Founder & CEO',
    image: t1saqibasghar,
    socials: { facebook: '#', Linkedin : 'https://pk.linkedin.com/in/muhammad-saqib-18b191284', instagram: '#' },
  },
  {
    name: 'Ahsan Ilyas',
    role: 'Chief Financial Officer (CFO)',
    image: t8ahsan,
    socials: { facebook: '#', Linkedin : 'https://www.linkedin.com/in/muhammad-ehsan-ilyas-77aa22274/', instagram: '#' },
  },
  {
    name: 'Hassnain Altaf',
    role: 'Chief Operating Officer (COO)',
    image: t2hassnainaltaf1,
    socials: { facebook: '#', Linkedin : 'https://www.linkedin.com/in/hassnain-altaf-385190231/', instagram: '#' },
  },
  {
    name: 'Zuhaab Ur Rehman',
    role: 'Full Stack Web Engineer',
    image: t3zuhaab,
    socials: { facebook: '#', Linkedin : 'https://www.linkedin.com/in/muhammad-zuhaab-ur-rahman-175a83234', instagram: '#' },
  },
  {
    name: 'Abdullah Sadaqat',
    role: 'Full Stack Web Developer',
    image: t4abdullah,
    socials: { facebook: '#', Linkedin : 'https://www.linkedin.com/in/abdullah-sadaqat-77960329b', instagram: '#' },
  },
  {
    name: 'Moiz Hanif',
    role: 'Full Stack Engineer',
    image: t3moiz,
    socials: { facebook: '#', Linkedin : 'https://www.linkedin.com/in/moiz-hanif-116a87238/', instagram: '#' },
  },
  {
    name: 'Salman Akhtar',
    role: 'SEO Expert',
    image: t6salman,
    socials: { facebook: '#', Linkedin : 'https://www.linkedin.com/in/muhammad-salman-akhtar-613761252/', instagram: '#' },
  },
  {
    name: 'Talha Ali',
    role: 'Python Developer',
    image: t7talha,
    socials: { facebook: '#', Linkedin : 'https://www.linkedin.com/in/talha-ali-452b13337', instagram: '#' },
  },
  {
    name: 'Rao Hassan',
    role: 'Laravel Developer',
    image: t9rao,
    socials: { facebook: '#', Linkedin : 'https://www.linkedin.com/in/rao-m-hassan/', instagram: '#' },
  },
  {
    name: 'Ali Hassan',
    role: 'IT Engineer',
    image: t10ali,
    socials: { facebook: '#', Linkedin : '#', instagram: '#' },
  },
];

const TeamSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <>
    <Helmet>
        <title>Meet Our Team | TechSol Technologies</title>
        <meta name="description" content="Meet the talented professionals behind TechSol Technologies and learn more about their expertise." />
      </Helmet>
      <Navbar />
      <div className="services-header" data-aos="fade-down">
        <div className="header-content">
          <h1 className="display-4" style={{fontWeight:'400'}}>Team</h1>
          <div className="breadcrumb mx-5">
            <Link to="/" className="breadcrumb-link" style={{color:'#002D65' , fontSize:'1.1rem'}}>Home</Link>
            <span className="breadcrumb-separator"> &gt; </span>
            <Link to="/team" className="breadcrumb-link active" style={{fontSize:'1.1rem'}}>Team</Link>
          </div>
        </div>
      </div>
      <div className="team-section py-5">
        <div className="container">
          <div className="row gy-4">
            <h2 className='team-heading'>Our Team</h2>
            {teamMembers.map((member, index) => (
              <div key={index} className="col-md-4">
                <di className="team-card-container">
                  <div className="team-card border">
                    <img
                      src={member.image}
                      alt={member.name}
                      className="img-fluid mb-4"
                    />
                    <h4 className="team-name">{member.name}</h4>
                    <h5 className="team-title">{member.role}</h5>
                    <div className="team-card-flip">
                      <a href={member.socials.Linkedin } className='fs-2' target="_blank" rel="noopener noreferrer">
                        <FaLinkedin />
                      </a>
                    </div>
                  </div>
                </di>
              </div>
            ))}
          </div>
        </div>
      </div>
      <DedicatedTeam />
      <Footer />
    </>
  );
};

export default TeamSection;
