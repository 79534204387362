import React,{useState} from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaPaperPlane } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Contactus = () => {

  const [fromdata , setFromdata] = useState({
    name:'',
    email:'',
    phone:'',
    message:''
  });
  const [isloading , setLoading] = useState(false);


  const handleContactForm = async(e)=>{
    e.preventDefault(); // Prevent the form from submitting and refreshing the page

    if( !fromdata.name || !fromdata.email || !fromdata.phone || !fromdata.message){
      toast.error('All fields are required');
      return;
    }

    else{
      console.log(fromdata);
      setLoading(true)
    try {
      const response = await axios.post('https://techsol-seven.vercel.app/api/contact', fromdata);

      if(response.status === 200){
        toast.success(response.data.message);
      }

    } catch (error) {

      if(error.response)
      toast.error(error.response.data.message);
    }
    finally{
      setLoading(false);
    }
    }
    
}  

  return (
    <div className="container-fluid py-5" style={{ backgroundColor: '#002D62', color: '#fff', padding: '2rem' }}>
      <div className="row align-items-center">
        {/* Left Column: Text */}
        <div className="col-md-6">
          <h2 className="fw-bold text-start">Request a call back</h2>
          <p className='text-start w-75 py-2'>
            Request a call back by leaving your name, contact number, and a brief message, and we will get back to you as soon as possible.
            Happy to know you!
          </p>
        </div>

        {/* Right Column: Contact Form */}
        <div className="col-md-6 py-md-4">
          <form method='POST' action='' onSubmit={handleContactForm}>
            <Link to="/contact" className='text-decoration-none'>
              <div className="row mb-3">
                <div className="col-md-6">
                  <input type="text" name='name' value={fromdata.name} onChange={(e) => setFromdata({ ...fromdata, name: e.target.value })}  className="form-control outline-none shadow-none rounded-0" placeholder="Name *" style={{ borderRadius: '8px' }} />
                </div>
                <div className="col-md-6">
                  <input type="email" name='email' value={fromdata.email} onChange={(e) => setFromdata({ ...fromdata, email: e.target.value })} className="form-control outline-none shadow-none rounded-0" placeholder="E-mail *" style={{ borderRadius: '8px' }} />
                </div>
              </div>
              <div className="mb-3">
                <input type="tel" name='phone' value={fromdata.phone} onChange={(e) => setFromdata({ ...fromdata, [e.target.name]: e.target.value })} className="form-control outline-none shadow-none rounded-0" placeholder="Phone" style={{ borderRadius: '8px' }} />
              </div>
              <div className="mb-3">
                <textarea name='message' value={fromdata.message} onChange={(e) => setFromdata({ ...fromdata, message: e.target.value })}  className="form-control outline-none shadow-none rounded-0" rows="4" placeholder="Content *" style={{ borderRadius: '8px' }}></textarea>
              </div>
            </Link>
            <div className="mb-3 d-flex justify-content-start">
              <button  type="submit" className="btn btn-primary rounded-0" style={{ backgroundColor: '#007bff', borderRadius: '8px' }}>
                <FaPaperPlane /> {isloading ? 'Processing....' : 'SEND MESSAGE'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
