// import React, { useState } from 'react';

// // Styles
// const containerStyle = {
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   padding: '20px',
 
// };

// const inputStyle = {
//   padding: '9.4px 20px',
//   fontSize: '16px',
//   borderTopLeftRadius : '25px',
//   borderBottomLeftRadius: '25px',
//   border: '2px solid #ccc',
//   width: '500px',
//   outline: 'none',
  
// };

// const placeholderStyle = {
//   color: '#aaa',
// };

// const svgStyle= {background:'#F8F8F8', padding: '9.7px 20px', borderTopRightRadius:'25px' , borderBottomRightRadius:'25px' , cursor:'pointer'}

// function SearchField() {
//   const [query, setQuery] = useState('');

//   const handleChange = (e) => {
//     setQuery(e.target.value);
//   };

//   return (

//     <div style={containerStyle}>
//       <input
//         type="text"
//         value={query}
//         onChange={handleChange}
//         placeholder="Search..."
//         style={inputStyle}
//       />

//       <div style={svgStyle} >
//      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#002d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-search"><circle cx="11" cy="11" r="8"/><path d="m21 21-4.3-4.3"/></svg>
        
//       </div>
     
//     </div>
//   );
// }

// export default SearchField;





import React, { useState } from 'react';

// Styles
const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  width: '100%',
  maxWidth: '1000px', // Set a max width for larger screens
};

const inputStyle = {
  padding: '9px 20px',
  fontSize: '16px',
  borderTopLeftRadius: '25px',
  borderBottomLeftRadius: '25px',
  border: '2px solid #ccc',
  outline: 'none',
  width: '100%', // Full width on smaller screens
  maxWidth: '500px', // Limit max width to prevent overflow
};

const suggestionsStyle = {
  position: 'absolute',
  backgroundColor: '#fff',
  border: '1px solid #ccc',
  width: '100%', // Full width on mobile
  maxWidth: '500px', // Limit max width for larger screens
  maxHeight: '200px',
  overflowY: 'auto',
  zIndex: '1',
};

const svgStyle = { 
  background: '#F8F8F8', 
  padding: '9.7px 20px', 
  borderTopRightRadius: '25px',
  borderBottomRightRadius: '25px',
  cursor: 'pointer' 
};

function SearchField({ value, onChange, suggestions, onSelect }) {
  const handleChange = (e) => {
    onChange(e.target.value); // Update search query in the parent component
  };

  const handleSelect = (email) => {
    onSelect(email); // Handle email selection and update search query
  };

  return (
    <div style={containerStyle} className=' d-none d-md-flex'>
      <input
        type="text"
        value={value}
        onChange={handleChange} // Trigger search on every keystroke
        placeholder="Search..."
        style={inputStyle}
      />
      <div style={svgStyle}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#002d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-search">
          <circle cx="11" cy="11" r="8" />
          <path d="m21 21-4.3-4.3" />
        </svg>
      </div>

      {/* Display suggestions below the search field */}
      <div className="position-absolute z-2" style={{ left: '0', top: '5rem' }}>
        {suggestions && suggestions.length > 0 && (
          <div className="list-group shadow-lg rounded overflow-y-scroll" style={suggestionsStyle}>
            {suggestions.map((item, index) => (
              <button
                key={index}
                className="list-group-item list-group-item-action py-2"
                style={{ borderBottom: '1px solid #ddd', paddingLeft: '8rem', paddingRight: '8rem' }}
                onClick={() => handleSelect(item.email)}>
                {item.email} {/* Display matched email */}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchField;



// {/* <div style={{position:'absolute' , left:'33rem' , top:'5rem' }}>
// {suggestions && suggestions.length > 0 && (
//   <div style={suggestionsStyle}>
//     {suggestions.map((item, index) => (
//       <div key={index} 
//            style={{ padding: '10px', borderBottom: '1px solid #ddd', cursor: 'pointer' }} 
//            onClick={() => handleSelect(item.email)}>
//         {item.email} {/* Display matched email */}
//       </div>
//     ))}
//   </div>
// )}
// </div> */}