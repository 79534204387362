import React, { useEffect } from "react";
import { FaCheckCircle, FaArrowRight } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/ServiceDetail.css"; // Custom CSS file for additional styling
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import bgseo from '../assets/images/service-seo.jpeg';

const ServiceDetail = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: "ease-in-out",
      once: true, // Animation only happens once
    });
  }, []);

  return (
    <>
      <div>
        <Navbar />
      </div>
      <div className="services-header" data-aos="fade-down">
        <div className="header-content">
          <h1 className="header-title">Services</h1>
          <div className="breadcrumb">
            <Link to="/" className="breadcrumb-link">
              Home
            </Link>
            <span className="breadcrumb-separator"> &gt; </span>
            <span className="breadcrumb-current">Services</span>
          </div>
        </div>
      </div>

      <div className="container my-5 service-detail">
        <div className="row">
          {/* Service List */}
          <div className="col-lg-4" data-aos="fade-right">
            <div className="service-list-container mt-md-4 p-4 rounded shadow">
              <ul className="list-unstyled">
                <li className="mb-2">
                  <Link to="/laravel" className="service-link active d-flex justify-content-between align-items-center">
                    LARAVEL <FaArrowRight />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/web-development" className="service-link d-flex justify-content-between align-items-center">
                    Web Development <FaArrowRight />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/wordpress" className="service-link d-flex justify-content-between align-items-center">
                    WORDPRESS <FaArrowRight />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/app-development" className="service-link d-flex justify-content-between align-items-center">
                    ANDROID & IOS <FaArrowRight />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/artificial-intelligence" className="service-link d-flex justify-content-between align-items-center">
                    ML & AI <FaArrowRight />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/uiux" className="service-link d-flex justify-content-between align-items-center">
                    UI / UX <FaArrowRight />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/seo-optimization" className="service-link d-flex justify-content-between align-items-center">
                    SEO Optimization <FaArrowRight />
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/graphic-desiging" className="service-link d-flex justify-content-between align-items-center">
                    Graphic Desiging <FaArrowRight />
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Content Section */}
          <div className="col-lg-8" data-aos="fade-left">
            <div className="quality-section mt-5">
              <h3 className="section-title text-start">History Of SEO</h3>
              <p className="quality-description text-start mb-4">
              The history of SEO traces back to the 1990s, evolving alongside search engines to optimize websites for better rankings and user experience.
              </p>
            </div>

            <div className="row align-items-center">
              <div className="col-md-7" data-aos="fade-up">
                <h2 className="section-title text-start">Scope Of SEO</h2>
                <p className="section-description text-start">
                The vast scope of SEO encompasses improving website visibility, driving organic traffic, and enhancing user engagement for long-term success.
                </p>
                <div className="features d-flex flex-row">
                  <div className="d-flex flex-column">
                    <div className="feature-item my-2">
                      <FaCheckCircle className="feature-icon mx-1" /> Full-stack
                      web development
                    </div>
                    <div className="feature-item my-2">
                      <FaCheckCircle className="feature-icon mx-1" /> API
                      development
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="feature-item my-2">
                      <FaCheckCircle className="feature-icon mx-1" /> Real-time
                      applications
                    </div>
                    <div className="feature-item my-2">
                      <FaCheckCircle className="feature-icon mx-1" /> Scalability
                    </div>
                  </div>
                </div>
              </div>

              {/* Image Section */}
              <div className="col-md-5 text-center" data-aos="zoom-in">
                <div className="image-box">
                  <img
                    src={bgseo}
                    alt="Laravel Logo"
                    className="logo-image" style={{objectFit: 'cover'}}
                  />
                </div>
              </div>
            </div>

            <div className="quality-section mt-5" data-aos="fade-up">
              <h3 className="quality-title text-start">
                Quality Industrial Working
              </h3>
              <p className="quality-description text-start">
              Implementing quality processes in industrial operations ensures efficiency, compliance, and enhanced productivity.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </>
  );
};

export default ServiceDetail;
