


import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SeeMoreServicesCard from '../pages/SeeMoreServicesCard';
import { IoIosArrowDroprightCircle } from "react-icons/io";
import '../assets/css/FeatureSection.css';
import '../assets/css/SeeMoreServices.css';
import bglaravel from '../assets/images/service-lara.jpg';
import bgweb from '../assets/images/service-web.jpeg';
import bguiux from '../assets/images/service-uiux.png';
import bggraphic from '../assets/images/service-graphic.jpg';
import bgAI from '../assets/images/service-AI.webp';
import bgapp from '../assets/images/service-app.jpg';
import bgwordp from '../assets/images/service-wordpress.png';
import bgseo from '../assets/images/service-seo.jpeg';
import { Link } from 'react-router-dom';

const FeatureSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 800, // Smooth animation timing
      easing: 'ease-in-out',
      once: true,
    });

    // Dynamically add the link to the stylesheet
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/devicon.min.css';
    document.head.appendChild(link);

    // Clean up by removing the link when the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, []);


  const services = [
    {
      title: 'Web Development',
      icon:<i class="devicon-fsharp-plain  text-white" style={{ fontSize: "48px"}}></i>,
      bgImage: bgweb,
      link: "/web-development"
    },
    {
      title: 'LARAVEL',
      icon: <i class="devicon-laravel-original text-white " style={{ fontSize: "48px"}}></i>,
      bgImage: bglaravel,
      link: "/laravel"
    },
    {
      title: 'App Development',
      icon:  <i class="devicon-java-plain  text-white" style={{ fontSize: "48px"}}></i>,
      bgImage: bgapp,
      link: "/app-development"
    },
    {
      title: 'ML & AI',
      icon: <i class="devicon-python-plain  text-white" style={{ fontSize: "48px"}}></i>,
      bgImage: bgAI,
      link: "/artificial-intelligence"
    },
    {
      title: 'Wordpress',
      icon: <i class="devicon-wordpress-plain  text-white" style={{color: "#002D65", fontSize: "48px"}}></i>,
      bgImage: bgwordp,
      link: "/wordpress"
    },
    {
      title: 'UI/UX',
      icon: <i class="devicon-antdesign-plain  text-white" style={{ fontSize: "48px"}}></i>,
      bgImage: bguiux,
      link: "/uiux"
    },
    {
      title: 'Graphic Designing',
      icon: <i class="devicon-gatsby-original  text-white" style={{ fontSize: "48px"}}></i>,
      bgImage: bggraphic,
      link: "/graphic-desiging"
    },
    {
      title: 'SEO',
      icon: <i class="devicon-gatsby-original  text-white" style={{ fontSize: "48px"}}></i>,
      bgImage: bgseo,
      link: "/seo-optimization"
    },

  ];

  return (
    <>

      <div className="feature-section container my-5 ">
            <div className="d-flex flex-md-row justify-content-between">
                <h2 className="service-heading text-start display-4" style={{ color: '#002D62' }}>Services</h2>
                <Link to='/services' className='text-decoration-none'>
                     <button className="more-button">
                         MORE <span className="arrow"><IoIosArrowDroprightCircle /></span>
                     </button>
                 </Link>
            </div>
            <div className="row">
                {services.map((service, index) => (
                  <Link to={service.link} key={index} className="col-lg-3 col-md-6 mt-4 pt-2 text-decoration-none">
                    <SeeMoreServicesCard className=""
                        key={index}
                        title={service.title}
                        icon={service.icon}
                        bgImage={service.bgImage}
                    />
                    </Link>
                ))}
            </div>
      </div>

    </>
  );
};

export default FeatureSection;


