import React from 'react';
import '../assets/css/FeatureSection.css';

const SeeMoreServicesCard = ({ title, icon, bgImage }) => (
  <div className="">
    <div
      className="card service-wrapper rounded border-0 shadow p-4"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        cursor: 'pointer',
      }}
    >
      {/* Gradient Overlay */}
      <div className="overlay-gradient"></div>

    </div>
      <div>
        <div className="content d-flex flex-row ">
          <div className="icon-container">
          <span className="icon fs-1" style={{color: "#002D65"}}>{icon}</span> {/* Render icon as JSX */}
          </div>
          <h5 className="title text-center">{title}</h5>
        </div>
      </div>
  </div>
);

export default SeeMoreServicesCard;
