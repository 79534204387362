import React, { useEffect  , useState} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SeeMoreServicesCard from '../pages/SeeMoreServicesCard';
import '../assets/css/FeatureSection.css';
import '../assets/css/SeeMoreServices.css';
import bglaravel from '../assets/images/service-lara.jpg';
import bgweb from '../assets/images/service-web.jpeg';
import bguiux from '../assets/images/service-uiux.png';
import bggraphic from '../assets/images/service-graphic.jpg';
import bgAI from '../assets/images/service-AI.webp';
import bgapp from '../assets/images/service-app.jpg';
import bgwordp from '../assets/images/service-wordpress.png';
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import CallToAction from '../components/CallToAction';
import bgseo from '../assets/images/service-seo.jpeg';
import { Helmet } from 'react-helmet';


const SeeMoreServices = () => {
  useEffect(() => {
    AOS.init({
      duration: 800, // Smooth animation timing
      easing: 'ease-in-out',
      once: true,
    });

    // Dynamically add the link to the stylesheet
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/devicon.min.css';
    document.head.appendChild(link);

    // Clean up by removing the link when the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, []);


  const services = [
    {
      title: 'Web Development',
      icon:<i class="devicon-fsharp-plain  text-white" style={{ fontSize: "48px"}}></i>,
      bgImage: bgweb,
      link: "/web-development"
    },
    {
      title: 'LARAVEL',
      icon: <i class="devicon-laravel-original text-white " style={{ fontSize: "48px"}}></i>,
      bgImage: bglaravel,
      link: "/laravel"
    },
    {
      title: 'App Development',
      icon:  <i class="devicon-java-plain  text-white" style={{ fontSize: "48px"}}></i>,
      bgImage: bgapp,
      link: "/app-development"
    },
    {
      title: 'ML & AI',
      icon: <i class="devicon-python-plain  text-white" style={{ fontSize: "48px"}}></i>,
      bgImage: bgAI,
      link: "/artificial-intelligence"
    },
    {
      title: 'Wordpress',
      icon: <i class="devicon-wordpress-plain  text-white" style={{color: "#002D65", fontSize: "48px"}}></i>,
      bgImage: bgwordp,
      link: "/wordpress"
    },
    {
      title: 'UI/UX',
      icon: <i class="devicon-antdesign-plain  text-white" style={{ fontSize: "48px"}}></i>,
      bgImage: bguiux,
      link: "/uiux"
    },
    {
      title: 'Graphic Designing',
      icon: <i class="devicon-gatsby-original  text-white" style={{ fontSize: "48px"}}></i>,
      bgImage: bggraphic,
      link: "/graphic-desiging"
    },
    {
      title: 'SEO',
      icon: <i class="devicon-gatsby-original  text-white" style={{ fontSize: "48px"}}></i>,
      bgImage: bgseo,
      link: "/seo-optimization"
    },

  ];

  // State to track whether the link is being hovered
  const [isHovered, setIsHovered] = useState(false);

  // Dynamic inline style
  const linkStyle = {
    fontSize: '1.1rem',
    color:'#002D65',
    textDecoration: isHovered ? 'underline' : 'none', // Apply underline when hovered
  };

  return (
    <>
     <Helmet>
        <title>Services | TechSol Technologies</title>
        <meta name="description" content="Explore the wide range of services offered by TechSol Technologies to help your business grow." />
      </Helmet>
      <Navbar />
      
      <div className="services-header" data-aos="fade-down">
      <div className="header-content">
        <h1 className="display-4" style={{fontWeight:'400'}}>Services</h1>
        <div className="breadcrumb mx-5">
          <Link to="/" className="breadcrumb-current" style={linkStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>Home</Link>
          <span className="breadcrumb-separator"> &gt; </span>
          <Link to="/services" className="breadcrumb-link active" style={{fontSize:'1.1rem'}} >Services</Link>
        </div>
      </div>
    </div>


      <div className="feature-section container my-5 ">
            <div className="d-flex flex-md-row justify-content-between py-3">
                <h2 className="service-heading text-start display-4" style={{ color: '#002D62' }}>Services</h2>
            </div>
            <div className="row">
                {services.map((service, index) => (
                  <Link to={service.link} key={index} className="col-lg-3 col-md-6 mt-4 pt-2 text-decoration-none">
                    <SeeMoreServicesCard className="d-flex justify-content-center"
                        key={index}
                        title={service.title}
                        icon={service.icon}
                        bgImage={service.bgImage}
                    />
                    </Link>
                ))}
            </div>
      </div>

      {/* <Contactus/> */}

      <CallToAction />

      <Footer className="mt-5" />
    </>
  );
};

export default SeeMoreServices;
