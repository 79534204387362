import React,{useState} from 'react'
import axios from 'axios';
import { toast } from 'react-toastify'; // Correct import

function Logout() {
  
  const [isLoading, setIsLoading] = useState(false);

  const handleLogout = async (e) => {
    e.preventDefault(); // Prevent the form from submitting and refreshing the page

    setIsLoading(true);
      try {
        const response = await axios.post('https://techsol-seven.vercel.app/api/logout',{}, {withCredentials:true} );
  
        if (response.data.message === 'Logout successful' && response.status === 200) {
          toast.success(response.data.message);
  
          setInterval(() => {
            window.location.href = '/techadmin'; // Redirect after a delay
          }, 2000);
        }
      } catch (error) {
        // Improved error handling
        if (error.response) {
          // Server-side error
          toast.error(error.response.data.message || 'Something went wrong');
        } else if (error.request) {
          // Request was made but no response received
          toast.error('No response from server');
        } else {
          // Other errors (e.g., network errors)
          toast.error('An error occurred: ' + error.message);
        }
      } finally {
        setIsLoading(false); // Stop loading indicator
      }
    }
   
  return (
    <div>   
        <button type="submit" className="btn me-5" onClick={handleLogout} style={{
            backgroundColor: '#fff',
            color: 'black',
            fontWeight: 'bold',
            border:'2px solid white',
            borderRadius: '10px',
            padding: '10px 20px'
        }}>
            {isLoading ? 'Loging out...' : 'Logout'}
        </button>
    </div>
  )
}

export default Logout
