import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify'; // Correct import

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the form from submitting and refreshing the page

    if (!email || !password) {
      toast.error('All fields are required');
      return;
    }

    else{
      setIsLoading(true);
      try {
        const response = await axios.post('https://techsol-seven.vercel.app/api/login', { email, password } , {withCredentials:true});
  
        if (response.data.message === 'Logged in successfully' && response.status === 200) {
          toast.success(response.data.message);
  
          setInterval(() => {
            window.location.href = '/techdashboard'; // Redirect after a delay
          }, 2000);
        }
      } catch (error) {
        // Improved error handling
        if (error.response) {
          // Server-side error
          toast.error(error.response.data.message || 'Something went wrong');
        } else if (error.request) {
          // Request was made but no response received
          toast.error('No response from server');
        } else {
          // Other errors (e.g., network errors)
          toast.error('An error occurred: ' + error.message);
        }
      } finally {
        setIsLoading(false); // Stop loading indicator
      }
    }
   
  };

  return (
    <div className="d-flex justify-content-center align-items-center overflow-hidden bg-light" style={{height:'85vh'}}>
      <div className="card shadow-lg p-5 w-sm:100 w-md-100 rounded-4" style={{ backgroundColor: '#fff' }}>
        <h3 className="text-center mb-4" style={{ color: '#002D62', fontWeight: 'bold' }}>Login</h3>
        <form onSubmit={handleLogin}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label fw-bold d-flex justify-content-start" style={{ color: '#002D62' }}>Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control rounded-3 border-0 shadow-sm"
              placeholder="Enter your email"
              style={{ backgroundColor: '#f1f6fb' }}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label fw-bold d-flex justify-content-start" style={{ color: '#002D62' }}>Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control rounded-3 border-0 shadow-sm"
              placeholder="Enter your password"
              style={{ backgroundColor: '#f1f6fb' }}
            />
          </div>
          <div className="mt-4">
            <button type="submit" className="btn w-100" style={{
              backgroundColor: '#002D62',
              color: '#fff',
              fontWeight: 'bold',
              borderRadius: '50px',
              padding: '10px'
            }}>
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
