import React, { useEffect } from "react";
import { FaCheckCircle, FaArrowRight } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/ServiceDetail.css"; // Custom CSS file for additional styling
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import imagelaravel from '../assets/images/service-lara.jpg'

const ServiceDetail = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: "ease-in-out",
      once: true, // Animation only happens once
    });
  }, []);

  return (
    <>
      <div>
        <Navbar />
      </div>
      <div className="services-header" data-aos="fade-down">
        <div className="header-content">
          <h1 className="header-title">Services</h1>
          <div className="breadcrumb">
            <Link to="/" className="breadcrumb-link">
              Home
            </Link>
            <span className="breadcrumb-separator"> &gt; </span>
            <span className="breadcrumb-current">Services</span>
          </div>
        </div>
      </div>

      <div className="container my-5 service-detail">
        <div className="row">
          {/* Service List */}
          <div className="col-lg-4" data-aos="fade-right">
            <div className="service-list-container mt-md-4 p-4 rounded shadow">
              <ul className="list-unstyled">
                <li className="mb-2">
                  <Link to="/" className="service-link active d-flex justify-content-between align-items-center">
                    LARAVEL <FaArrowRight />
                  </Link>
                </li>
                <li className="mb-2">
                  <a href="#" className="service-link d-flex justify-content-between align-items-center">
                    WORDPRESS <FaArrowRight />
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="service-link d-flex justify-content-between align-items-center">
                    FLUTTER (ANDROID & IOS) <FaArrowRight />
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="service-link d-flex justify-content-between align-items-center">
                    NATIVE ANDROID APP (JAVA) <FaArrowRight />
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="service-link d-flex justify-content-between align-items-center">
                    SEO, SEM & ASO <FaArrowRight />
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="service-link d-flex justify-content-between align-items-center">
                    SOCIAL MEDIA MARKETING <FaArrowRight />
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="service-link d-flex justify-content-between align-items-center">
                    ML & AI <FaArrowRight />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Content Section */}
          <div className="col-lg-8" data-aos="fade-left">
            <div className="quality-section mt-5">
              <h3 className="section-title text-start">History Of Laravel</h3>
              <p className="quality-description text-start mb-4">
                Laravel can be a strong contender for building web applications
                that serve industrial needs, especially those involving data
                management, user interfaces, and API integrations.
              </p>
            </div>

            <div className="row align-items-center">
              <div className="col-md-7" data-aos="fade-up">
                <h2 className="section-title text-start">Scope Of Laravel</h2>
                <p className="section-description text-start">
                  Laravel's scope extends beyond just finance and accounting
                  applications.
                </p>
                <div className="features d-flex flex-row">
                  <div className="d-flex flex-column">
                    <div className="feature-item my-2">
                      <FaCheckCircle className="feature-icon mx-1" /> Full-stack
                      web development
                    </div>
                    <div className="feature-item my-2">
                      <FaCheckCircle className="feature-icon mx-1" /> API
                      development
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="feature-item my-2">
                      <FaCheckCircle className="feature-icon mx-1" /> Real-time
                      applications
                    </div>
                    <div className="feature-item my-2">
                      <FaCheckCircle className="feature-icon mx-1" /> Scalability
                    </div>
                  </div>
                </div>
              </div>

              {/* Image Section */}
              <div className="col-md-5 text-center" data-aos="zoom-in">
                <div className="image-box">
                  <img
                    src={imagelaravel}
                    alt="Laravel Logo"
                    className="logo-image"
                  />
                </div>
              </div>
            </div>

            <div className="quality-section mt-5" data-aos="fade-up">
              <h3 className="quality-title text-start">
                Quality Industrial Working
              </h3>
              <p className="quality-description text-start">
                Laravel can be a strong contender for building web applications that
                serve industrial needs, especially those involving data management,
                user interfaces, and API integrations.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </>
  );
};

export default ServiceDetail;
