import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie'; // Ensure js-cookie is installed

function PrivateRoute() {
  // Check if the 'token' cookie exists
  const token = Cookies.get('token');

  // If no token is found, redirect to the login page
  if (!token) {
    return <Navigate to="/techadmin" />;
  }


  // If the user is a superadmin, allow access to the protected route
  return (
    <>
      <Outlet /> {/* This will render the nested routes */}
    </>
  );
}

export default PrivateRoute;