import React from 'react';
import { InView } from 'react-intersection-observer';
import { FaRegCheckCircle } from 'react-icons/fa';
import '../assets/css/StorySection.css'; 
import 'animate.css';
import visionImage from '../assets/images/vision-image.png'

const Vision = () => (
  <div className="container my-5">
    <div className="row align-items-center">
      <div className="col-md-7">
        <h2 className="d-flex justify-content-start vision-heading" >Our Vision</h2>
        <hr className="my-2" style={{ width: '60px', borderTop: '5px solid #007bff', borderRadius: '10px' }} />
        <p className='' style={{fontSize: '17px', textAlign: 'justify'}} >Our vision is to be a leader in providing cutting-edge technology solutions that drive business success. We strive to empower companies with innovative tools that enhance efficiency, creativity, and growth.</p>
        <ul className='text-start list-unstyled'>
          <li style={{listStyle:'inherit'}}><FaRegCheckCircle className='pb-1' size={20} color="#002D65" />Create Limitless Possibilities</li>
          <li style={{listStyle:'inherit'}}><FaRegCheckCircle className='pb-1' size={20} color="#002D65" />Empower Businesses</li>
          <li style={{listStyle:'inherit'}}><FaRegCheckCircle className='pb-1' size={20} color="#002D65" />Lead Digital Innovation:</li>
        </ul>
      </div>
      <div className="col-md-5">
        <InView triggerOnce>
          {({ inView, ref }) => (
            <img
              ref={ref}
              src={visionImage}
              alt="Vision"
              className={`img-fluid rounded ${inView ? 'animate__animated animate__fadeInRight' : ''}`}
            />
          )}
        </InView>
      </div>
    </div>
  </div>
);

export default Vision;
