// import React from 'react'

// function Notification() {

//     const styleNotification={
//         border: '1px solid white',
//         borderRadius: '5px',
//         color: '#ffffff',
//         padding: '8px',
//         zIndex: '1',
//         cursor:'pointer'
//         onhover:{
//             backgroundColor: '#007bff',
//             color: '#ffffff',
//             cursor: 'pointer'
//         }
//     }
//   return (
//     <div style={styleNotification}>
//       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-bell"><path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9"/><path d="M10.3 21a1.94 1.94 0 0 0 3.4 0"/></svg>
//     </div>
//   )
// }

// export default Notification


import React, { useState } from 'react';

const Notification = ({ unreadCount }) => {
  const [isHovered, setIsHovered] = useState(false);

  const styleNotification = {
    position: 'relative',
    border: '1px solid white',
    borderRadius: '5px',
    color: '#ffffff',
    padding: '8px',
    zIndex: '1',
    cursor: 'pointer',
    backgroundColor: isHovered ? '#175091' : 'transparent',
  };

  const dotStyle = {
    position: 'absolute',
    top: '0',
    right: '0',
    width: '10px', // Fixed size for the dot
    height: '10px', // Fixed size for the dot (to make it circular)
    borderRadius: '50%', // To make it a circle
    backgroundColor: 'red', // Red color for the notification dot
    display: unreadCount > 0 ? 'block' : 'none', // Show the dot only if there are unread notifications
    padding: '0', // No padding required
  };

  return (
    <div
      style={styleNotification}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Bell icon */}
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9" />
        <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0" />
      </svg>

      {/* Notification dot */}
      <div style={dotStyle}></div>

      {/* Display the unread count if it's greater than 0 */}
      {unreadCount > 0 && (
        <div style={{ color: 'white', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '0.7rem' }}>
          {unreadCount}
        </div>
      )}
    </div>
  );
};

export default Notification;
