
import React, { useState } from 'react';
import { FaFacebook, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
import { IoMailUnread } from "react-icons/io5";
import { IoLogoLinkedin } from "react-icons/io";
import { FaSquareUpwork } from "react-icons/fa6";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Navbar.css';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import logo from '../../src/assets/images/TechSol.png'
import wattsappicon from '../../src/assets/images/wattsap2.png'

const Navbar = () => {
  const [isNavbarOpen, setNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setNavbarOpen(!isNavbarOpen);
  };

  return (
    <>
      <header className="py-2 z-index" style={{ backgroundColor: '#002D62' }}>

      <div className="container d-flex justify-content-between">

        <div className="container d-flex justify-content-between">

        <div className='d-flex flex-column flex-md-row align-items-start'>
            <Link to="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-dark mx-2 text-white text-decoration-none d-flex gap-1 align-items-center justify-content-center">
              <FaMapMarkerAlt /><small className="text-truncate ml-2">Raiwind Rd, Block Nawab Town, Lahore</small>
            </Link>
            <Link to="mailto: jointechsol@gmail.com" target="_blank" rel="noopener noreferrer" className="text-dark mx-2 text-white text-decoration-none d-flex gap-1 align-items-center justify-content-center">
              <IoMailUnread /><small> jointechsol@gmail.com</small>
            </Link>
        </div>
          <div className='float-left'>
            <Link to="tel:+923471428593" target="_blank">
              <img width="21" height="21" src={wattsappicon} alt="whatsapp--v1"/>
            </Link>
            <Link to="https://www.facebook.com/profile.php?id=61571234807734&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
              <FaFacebook />
            </Link>
            <Link to="https://www.linkedin.com/company/techsoltech/" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
              <IoLogoLinkedin />
            </Link>
          </div>
        </div>
        </div>
      </header>

      <nav className="navbar navbar-expand-lg navbar-light bg-white shadow py-2 sticky-top">
        <div className="container">
          <Link className="navbar-brand d-flex align-items-center" to="/">
          <span>
              <img src={logo} height={45} width={140} style={{objectFit: 'cover'}} alt="logo" />
        </span>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            aria-controls="navbarNav"
            aria-expanded={isNavbarOpen ? 'true' : 'false'}
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`collapse navbar-collapse mx-2  ${isNavbarOpen ? 'show' : ''}`} id="navbarNav">
            <ul className="navbar-nav me-auto justify-content-lg-start justify-content-center">
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" style={{ color: '#002D62' }} to="/">Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" style={{ color: '#002D62' }} to="/about">About Us</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" style={{ color: '#002D62' }} to="/services">Services</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" style={{ color: '#002D62' }} to="/team">Team</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" style={{ color: '#002D62' }} to="/contact">Contact</NavLink>
              </li>
            </ul>

            <div className="d-flex justify-content-center align-items-center mx-auto flex-column flex-sm-row">
            <div className="d-flex align-items-center me-4">
              <div className="bg-primary rounded-circle p-2 d-flex align-items-center justify-content-center" style={{ width: '40px', height: '40px' }}>
                <FaPhoneAlt color="white" />
              </div>
              <div className="ms-2">
                <small className="d-block text-muted">Hot Line Number</small>
                <a href="tel:+923471428593" className="text-decoration-none text-dark font-weight-bold">+92 347 14 28 593</a>
              </div>
            </div>
          </div>

          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
