// import React,{useState , useEffect} from 'react'
// import AdminNavbar from './AdminNavbar'
// import Logout from './Logout'
// import SearchField from './SearchField'
// import Notification from './Notification'
// import axios from 'axios';
// import {toast} from 'react-toastify'

// function Dashboard({ onNotificationClick }) {
//   const [notifications, setNotifications] = useState([]);

//  useEffect(() => {
//     // Fetch notifications from the API
//     axios.get('http://localhost:8000/notifications')
//       .then((response) => {
//         setNotifications(response.data);
//         toast.success( response.data.message ||'Notifications fetched successfully', {autoClose: 3000});
//       })
//       .catch((error) => {
//         console.error('Error fetching notifications:', error);
//       });
//   }, []);

//   const handleNotificationClick = (notificationId) => {
//     // Mark notification as seen when clicked
//     axios.patch(`http://localhost:8000/notification/${notificationId}`)
//       .then((response) => {
//         if (response.status === 200) {
//           // Trigger the detailed view page
//           onNotificationClick(notificationId);
//         }
        
//       })
//       .catch((error) => {
//         console.error('Error marking notification as seen:', error);
//       });
//   };

//   return (
//     <>
//     <div>
//     <div className='d-flex justify-content-between align-items-center' style={{
//       backgroundColor: '#002D62'
//     }}>
//     <AdminNavbar />
//     <SearchField />
//     <div className='d-flex gap-4 justify-content-center align-items-center'>
//     <Notification />
//     <Logout />
//     </div>
//     </div>

//     <div className='p-5'>

//     <div>
//       {notifications.map((notification) => (
//         <div key={notification._id} className="card mb-3" onClick={() => handleNotificationClick(notification._id)}>
//           <div className="card-body d-flex justify-content-between">
//             <div>{notification.email}</div>
//             <div>{new Date(notification.createdAt).toLocaleString()}</div>
//             <div>{notification.seen ? 'Seen' : 'Unseen'}</div>
//           </div>
//         </div>
//       ))}
//     </div>
//     </div>
  
//     </div>
//     </>
//   )
// }

// export default Dashboard


// ============================================================


// import React, { useState, useEffect } from 'react';
// import AdminNavbar from './AdminNavbar';
// import Logout from './Logout';
// import SearchField from './SearchField';
// import NotificationDetail from './NotificationDetail';
// import axios from 'axios';
// import { toast } from 'react-toastify';

// function Dashboard() {
//   const [notifications, setNotifications] = useState([]);
//   const [selectedNotificationId, setSelectedNotificationId] = useState(null); // To track selected notification
//   const [currentIndex, setCurrentIndex] = useState(0); // Track the index of notifications to load
//   const [hasMore, setHasMore] = useState(true); // Track if there are more notifications to load
//   const [loading, setLoading] = useState(false); // Track loading state for "Load More"

//   // Fetch notifications from API
//   const fetchNotifications = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.get('http://localhost:8000/notifications');
//       const newNotifications = response.data;
      
//       // Show only first 8 notifications initially
//       if (currentIndex === 0) {
//         setNotifications(newNotifications.slice(0, 8));
//       }

//       // Check if there are more notifications to load
//       if (newNotifications.length > currentIndex + 8) {
//         setHasMore(true);
//       } else {
//         setHasMore(false);
//       }
//     } catch (error) {
//       console.error('Error fetching notifications:', error);
//       toast.error('Error fetching notifications');
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchNotifications();
//   }, []);

//   const handleNotificationClick = (notificationId) => {
//     // Mark notification as seen when clicked
//     axios.patch(`http://localhost:8000/notification/${notificationId}`)
//       .then((response) => {
//         if (response.status === 200) {
//           // After marking it as seen, show the detail view
//           setSelectedNotificationId(notificationId);
//         }
//       })
//       .catch((error) => {
//         console.error('Error marking notification as seen:', error);
//       });
//   };

//   const handleBackClick = () => {
//     setSelectedNotificationId(null); // Go back to the list view
//     window.location.reload();
//   };

//   const handleLoadMore = () => {
//     // Load next 8 notifications
//     const nextIndex = currentIndex + 8;
//     const newNotifications = notifications.slice(); // Copy current notifications

//     axios.get('http://localhost:8000/notifications')
//       .then((response) => {
//         const nextNotifications = response.data.slice(nextIndex, nextIndex + 8);
//         if (nextNotifications.length > 0) {
//           setNotifications([...newNotifications, ...nextNotifications]);
//           setCurrentIndex(nextIndex);
//         } else {
//           setHasMore(false); // No more notifications to load
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching more notifications:', error);
//         toast.error('Error fetching more notifications');
//       });
//   };

//   return (
//     <div>
//       <div className='d-flex justify-content-between align-items-center' style={{
//         backgroundColor: '#002D62'
//       }}>
//         <AdminNavbar />
//         <SearchField />
//         <div className='d-flex gap-4 justify-content-center align-items-center'>
//           <Logout />
//         </div>
//       </div>

//       <div className='p-5'>
//         {selectedNotificationId ? (
//           <NotificationDetail notificationId={selectedNotificationId} onBack={handleBackClick} />
//         ) : (
//           <div>
//             {notifications.map((notification) => (
//               <div key={notification._id} className="card mb-3" onClick={() => handleNotificationClick(notification._id)}>
//                 <div className="card-body d-flex justify-content-between">
//                   <div>{notification.email}</div>
//                   <div>{new Date(notification.createdAt).toLocaleString()}</div>
//                   <div>{notification.seen ? 'Seen' : 'Unseen'}</div>
//                 </div>
//               </div>
//             ))}
            
//             {hasMore && (
//               <div className="text-center mt-4">
//                 <button className="btn btn-primary" onClick={handleLoadMore} disabled={loading}>
//                   {loading ? 'Loading...' : 'Load More'}
//                 </button>
//               </div>
//             )}
//             {!hasMore && <div className="text-center mt-4">No more notifications</div>}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Dashboard;


// ========================= > 2 [work correctly but not have  'No load more' Div] < ===============================


// import React, { useState, useEffect } from 'react';
// import AdminNavbar from './AdminNavbar';
// import Logout from './Logout';
// import SearchField from './SearchField';
// import NotificationDetail from './NotificationDetail';
// import Notification from './Notification';
// import axios from 'axios';
// import { toast } from 'react-toastify';

// function Dashboard() {
//   const [notifications, setNotifications] = useState([]);
//   const [filteredNotifications, setFilteredNotifications] = useState([]);
//   const [selectedNotificationId, setSelectedNotificationId] = useState(null); // To track the selected notification
//   const [searchQuery, setSearchQuery] = useState(''); // The query entered in the search bar
//   const [suggestions, setSuggestions] = useState([]); // To store dynamic suggestions
//   const [currentIndex, setCurrentIndex] = useState(0); // Track the current index for pagination
//   const itemsPerPage = 7; // Number of notifications to show per page

//   // Fetch notifications from API
//   const fetchNotifications = async () => {
//     try {
//       const response = await axios.get('http://localhost:8000/notifications');
//       const newNotifications = response.data;
//       setNotifications(newNotifications);
//       setFilteredNotifications(sortNotifications(newNotifications).slice(0, currentIndex + itemsPerPage)); // Sort and show first page
//     } catch (error) {
//       console.error('Error fetching notifications:', error);
//       toast.error('Error fetching notifications');
//     }
//   };

//   useEffect(() => {
//     fetchNotifications();
//   }, []);

//   // Function to sort notifications with unseen notifications on top
//   const sortNotifications = (notifications) => {
//     return notifications.sort((a, b) => {
//       if (!a.seen && b.seen) return -1;  // a is unseen, b is seen, so a comes first
//       if (a.seen && !b.seen) return 1;   // a is seen, b is unseen, so b comes first
//       return 0;  // If both have the same seen status, keep their order
//     });
//   };

//   const handleSearchChange = (query) => {
//     setSearchQuery(query);

//     if (query === '') {
//       setSuggestions([]);
//       setFilteredNotifications(sortNotifications(notifications).slice(0, currentIndex + itemsPerPage)); // Re-sort after filtering
//     } else {
//       const filtered = notifications.filter((notification) => {
//         const searchStr = query.toLowerCase();
//         return (
//           notification.email.toLowerCase().includes(searchStr) ||
//           (notification.name && notification.name.toLowerCase().includes(searchStr))
//         );
//       });
//       setSuggestions(filtered);
//       setFilteredNotifications(sortNotifications(filtered).slice(0, currentIndex + itemsPerPage)); // Re-sort after filtering
//     }
//   };

//   const handleSelectEmail = (email) => {
//     setSearchQuery(email);
//     setSuggestions([]);
//     const filtered = notifications.filter((notification) => notification.email === email);
//     setFilteredNotifications(sortNotifications(filtered)); // Re-sort after filtering by email
//   };

//   const handleNotificationClick = async (notificationId) => {
//     setSelectedNotificationId(notificationId); // Open detail view

//     // Find the notification in the list
//     const notificationIndex = notifications.findIndex((n) => n._id === notificationId);

//     if (notificationIndex !== -1 && !notifications[notificationIndex].seen) {
//       // Update status locally
//       const updatedNotifications = [...notifications];
//       updatedNotifications[notificationIndex].seen = true;
//       setNotifications(updatedNotifications);

//       // Persist status update to the server
//       // Mark notification as seen when clicked
//       axios.patch(`http://localhost:8000/notification/${notificationId}`, {
//         seen: true,
//         new: true
//       })
//       .then((response) => {
//         if (response.status === 200) {
//           setSelectedNotificationId(notificationId);
//         }
//       })
//       .catch((error) => {
//         console.error('Error marking notification as seen:', error);
//         toast.error('Failed to update notification status');
//       });
//     }
//   };

//   const handleBackClick = () => {
//     setSelectedNotificationId(null);
//   };

//   const loadMoreNotifications = () => {
//     const nextIndex = currentIndex + itemsPerPage;
//     setCurrentIndex(nextIndex);
//     const currentList = searchQuery
//       ? notifications.filter((notification) => {
//           const searchStr = searchQuery.toLowerCase();
//           return (
//             notification.email.toLowerCase().includes(searchStr) ||
//             (notification.name && notification.name.toLowerCase().includes(searchStr))
//           );
//         })
//       : notifications;
//     setFilteredNotifications(sortNotifications(currentList).slice(0, nextIndex + itemsPerPage)); // Re-sort after loading more
//   };

//   // Calculate the unread notifications count
//   const unreadNotificationsCount = notifications.filter(notification => !notification.seen).length;

//   return (
//     <div>
//       <div className="d-flex justify-content-between align-items-center" style={{ backgroundColor: '#002D62' }}>
//         <AdminNavbar />
//         <SearchField value={searchQuery} onChange={handleSearchChange} suggestions={suggestions} onSelect={handleSelectEmail} />
//         <div className="d-flex gap-4 justify-content-center align-items-center">
//           <Notification unreadCount={unreadNotificationsCount} />
//           <Logout />
//         </div>
//       </div>

//       <div className="p-3" style={{ cursor: 'pointer' }}>
//         {selectedNotificationId ? (
//           <NotificationDetail notificationId={selectedNotificationId} onBack={handleBackClick} />
//         ) : (
//           <div>
//             <div className="d-flex justify-content-between align-items-center px-3">
//               <div className="fw-bold fs-5">Email</div>
//               <div className="fw-bold fs-5">Send Time</div>
//               <div className="fw-bold fs-5">Status</div>
//             </div>
//             {filteredNotifications.map((notification) => (
//               <div key={notification._id} className="card mb-3 shadow-sm border-1 border-info" onClick={() => handleNotificationClick(notification._id)}>
//                 <div className="card-body d-flex justify-content-between align-items-center bg-white text-dark">
//                   <div className="d-flex justify-content-between align-items-center w-100">
//                     <div className="text-truncate">
//                       <strong className="text-dark">{notification.email}</strong>
//                     </div>
//                     <div>
//                       <span>{new Date(notification.createdAt).toLocaleString()}</span>
//                     </div>
//                     <div className={`badge ${notification.seen ? 'bg-success' : 'bg-warning'} text-white`} style={{ fontSize: '0.8rem' }}>
//                       {notification.seen ? 'Seen' : 'Unseen'}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}

//             {filteredNotifications.length < notifications.filter((notification) => {
//               const searchStr = searchQuery.toLowerCase();
//               return (
//                 !searchQuery ||
//                 notification.email.toLowerCase().includes(searchStr) ||
//                 (notification.name && notification.name.toLowerCase().includes(searchStr))
//               );
//             }).length && (
//               <button className="btn btn-primary" onClick={loadMoreNotifications}>
//                 Load More
//               </button>
//             )}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Dashboard;









// ===================================== >  3 < =======================================


import React, { useState, useEffect } from 'react';
import AdminNavbar from './AdminNavbar';
import Logout from './Logout';
import SearchField from './SearchField';
import NotificationDetail from './NotificationDetail';
import Notification from './Notification';
import axios from 'axios';
import { toast } from 'react-toastify';

function Dashboard() {
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null); // To track the selected notification
  const [searchQuery, setSearchQuery] = useState(''); // The query entered in the search bar
  const [suggestions, setSuggestions] = useState([]); // To store dynamic suggestions
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current index for pagination
  const itemsPerPage = 7; // Number of notifications to show per page

  // Fetch notifications from API
  const fetchNotifications = async () => {
    try {
      const response = await axios.get('https://techsol-seven.vercel.app/api/notifications');
      const newNotifications = response.data;
      setNotifications(newNotifications);
      setFilteredNotifications(sortNotifications(newNotifications).slice(0, currentIndex + itemsPerPage)); // Sort and show first page
    } catch (error) {
      console.error('Error fetching notifications:', error);
      toast.error('Error fetching notifications');
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  // Function to sort notifications with unseen notifications on top
  const sortNotifications = (notifications) => {
    return notifications.sort((a, b) => {
      if (!a.seen && b.seen) return -1;  // a is unseen, b is seen, so a comes first
      if (a.seen && !b.seen) return 1;   // a is seen, b is unseen, so b comes first
      return 0;  // If both have the same seen status, keep their order
    });
  };

  const handleSearchChange = (query) => {
    setSearchQuery(query);

    if (query === '') {
      setSuggestions([]);
      setFilteredNotifications(sortNotifications(notifications).slice(0, currentIndex + itemsPerPage)); // Re-sort after filtering
    } else {
      const filtered = notifications.filter((notification) => {
        const searchStr = query.toLowerCase();
        return (
          notification.email.toLowerCase().includes(searchStr) ||
          (notification.name && notification.name.toLowerCase().includes(searchStr))
        );
      });
      setSuggestions(filtered);
      setFilteredNotifications(sortNotifications(filtered).slice(0, currentIndex + itemsPerPage)); // Re-sort after filtering
    }
  };

  const handleSelectEmail = (email) => {
    setSearchQuery(email);
    setSuggestions([]);
    const filtered = notifications.filter((notification) => notification.email === email);
    setFilteredNotifications(sortNotifications(filtered)); // Re-sort after filtering by email
  };

  const handleNotificationClick = async (notificationId) => {
    setSelectedNotificationId(notificationId); // Open detail view

    // Find the notification in the list
    const notificationIndex = notifications.findIndex((n) => n._id === notificationId);

    if (notificationIndex !== -1 && !notifications[notificationIndex].seen) {
      // Update status locally
      const updatedNotifications = [...notifications];
      updatedNotifications[notificationIndex].seen = true;
      setNotifications(updatedNotifications);

      // Persist status update to the server
      // Mark notification as seen when clicked
      axios.patch(`https://techsol-seven.vercel.app/notification/${notificationId}`, {
        seen: true,
        new: true
      })
      .then((response) => {
        if (response.status === 200) {
          setSelectedNotificationId(notificationId);
        }
      })
      .catch((error) => {
        console.error('Error marking notification as seen:', error);
        toast.error('Failed to update notification status');
      });
    }
  };

  const handleBackClick = () => {
    setSelectedNotificationId(null);
  };

  const loadMoreNotifications = () => {
    const nextIndex = currentIndex + itemsPerPage;
    setCurrentIndex(nextIndex);
    const currentList = searchQuery
      ? notifications.filter((notification) => {
          const searchStr = searchQuery.toLowerCase();
          return (
            notification.email.toLowerCase().includes(searchStr) ||
            (notification.name && notification.name.toLowerCase().includes(searchStr))
          );
        })
      : notifications;
    setFilteredNotifications(sortNotifications(currentList).slice(0, nextIndex + itemsPerPage)); // Re-sort after loading more
  };

  // Calculate the unread notifications count
  const unreadNotificationsCount = notifications.filter(notification => !notification.seen).length;


  return (
    <div>
    
      <div className="d-flex justify-content-between align-items-center" style={{ backgroundColor: '#002D62' }}>
        <AdminNavbar />
        <SearchField value={searchQuery} onChange={handleSearchChange} suggestions={suggestions} onSelect={handleSelectEmail} />
        <div className="d-flex gap-4 justify-content-center align-items-center">
          <Notification unreadCount={unreadNotificationsCount} />
          <Logout />
        </div>
      </div>

      <div className="p-3" style={{ cursor: 'pointer' }}>
        {selectedNotificationId ? (
          <NotificationDetail notificationId={selectedNotificationId} onBack={handleBackClick} />
        ) : (
          <div>
            <div className="d-flex justify-content-between align-items-center px-md-3 px-4">
              <div className="fw-bold fs-5">Email</div>
              <div className="fw-bold fs-5">Send Time</div>
              <div className="fw-bold fs-5">Status</div>
            </div>
            {filteredNotifications.map((notification) => (
              <div key={notification._id} className="card mb-3 shadow-sm border-1 border-info  mx-md-0 mx-3" onClick={() => handleNotificationClick(notification._id)}>
                <div className="card-body d-flex justify-content-between align-items-center bg-white text-dark">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="text-truncate">
                      <strong className="text-dark">{notification.email}</strong>
                    </div>
                    <div>
                      <span>{new Date(notification.createdAt).toLocaleString()}</span>
                    </div>
                    <div className={`badge ${notification.seen ? 'bg-success' : 'bg-warning'} text-white`} style={{ fontSize: '0.8rem' }}>
                      {notification.seen ? 'Seen' : 'Unseen'}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {filteredNotifications.length < notifications.filter((notification) => {
        const searchStr = searchQuery.toLowerCase();
        return (
          !searchQuery ||
          notification.email.toLowerCase().includes(searchStr) ||
          (notification.name && notification.name.toLowerCase().includes(searchStr))
          );
          }).length ? (
            <button className="btn btn-primary" onClick={loadMoreNotifications}>
              Load More
            </button>
          ) : (
          <div className="text-center mt-3 text-info fw-bold fs-5 border-4 border-info d-inline-block px-3 py-1 rounded-3 bg-dark">
            No more to load
          </div>

         )}

          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;



