import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/GlanceWork.css';
import glanceImg from '../assets/images/glance-img.webp';
import AOS from 'aos';
import 'aos/dist/aos.css';
import professionateam from '../assets/images/professional-team.jpg'

const DedicatedTeam = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="container my-5">
      <div className="row align-items-center">
        {/* Image Section */}
        <div className="col-md-6 professional-img" data-aos="fade-right">
          <img
            src={professionateam} 
            alt="banner"
            className="img-fluid rounded"
          />
        </div>
        
        {/* Text Section */}
        <div className="col-md-6" data-aos="fade-left">
          <div className="p-3 text-content">
            <h2 className="mb-3 text-start professional-heading">Professional Individuals</h2>
            <p className="text-muted" style={{textAlign: "justify", fontSize: "17px"}}>
            A dedicated team consists of individuals who are specifically assigned to work together on a project.They are chosen for their expertise and and skills.Professional individuals are highly competent and adhere to ethical standards.
            When combined, a dedicated team of professionals brings together skills, knowledge, and a shared commitment to achieving and success in a project.They are valued assets for organizations aiming for efficient and excellent results.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DedicatedTeam;
