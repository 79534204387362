import React from 'react';
import '../assets/css/CallToAction.css'; // Custom CSS file for styling
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const CallToAction = () => {
  return (
    <div className="cta-section d-flex align-items-center justify-content-center">
      <div className="container text-center text-white cta-content">
        <h2 className="cta-title get-started-heading">Let's Get Started</h2>
        <p className="cta-subtitle text-dark">
        Transform your digital infrastructure with our cutting-edge IT services. Together, we'll create innovative solutions for your business challenges.
        </p>
        <Link to="/contact">
          <button className="btn get-started-button">Get started</button>
        </Link>
      </div>
    </div>
  );
};

export default CallToAction;
