// src/components/AboutSection.jsx
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Vision from './Vision';
import Mission from './Mission';
import StorySection from './StorySection';
import '../assets/css/Aboutus.css'
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Contactus from '../components/Contactus';
import { Helmet } from 'react-helmet';




const AboutSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <>
    <Helmet>
        {/* <title>TechSol Technologies | About Us</title> */}
        <title>About Us | TechSol Technologies </title>
        <meta name="description" content="Learn more about TechSol Technologies and our services." />
      </Helmet>
      <div>
        <Navbar />
      </div>
      <div className="aboutus-header" data-aos="fade-down">
        <div className="header-content">
          {/* <h1 className="header-title ">About us</h1> */}
          <h1 className="display-4" style={{fontWeight:'400'}}>About us</h1>
          <div className="breadcrumb mx-5">
            <Link to="/" className="breadcrumb-link" style={{color:'#002D65' , fontSize:'1.1rem'}}>Home</Link>
            <span className="breadcrumb-separator"> &gt; </span>
            {/* <span className="breadcrumb-current">Services</span> */}
            <Link to="/about" className="breadcrumb-link active" style={{fontSize:'1.1rem'}}>About Us</Link>
          </div>
        </div>
      </div>

      <div data-aos="fade-up">
        <StorySection />
      </div>
      
      <div data-aos="fade-up" data-aos-delay="100">
        <Vision />
      </div>
      
      <div data-aos="fade-up" data-aos-delay="200">
        <Mission />
      </div>
      
      <div>
       <Contactus/>
      </div>
      
      <div>
        <Footer />
      </div>
    </>
  );
};

export default AboutSection;
