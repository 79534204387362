// import React,{useState , useEffect} from 'react';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '../assets/css/ContactTab.css';
// import { FaMapMarkerAlt, FaPhoneAlt, FaClock, FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
// import Navbar from './Navbar';
// import Footer from './Footer';
// import { Link } from 'react-router-dom';

// const ContactTab = () => {

//   const [fromdata , setFromdata] = useState({
//     name:'',
//     email:'',
//     phone:'',
//     message:''
//   });
//   const [isloading , setLoading] = useState(false);


//   const handleContactForm = async(e)=>{
//     e.preventDefault(); // Prevent the form from submitting and refreshing the page

//     if( !fromdata.name || !fromdata.email || !fromdata.phone || !fromdata.message){
//       toast.error('All fields are required');
//       return;
//     }

//     else{
//       // console.log(fromdata)
//       setLoading(true)
//       try {
//       console.log(fromdata);
//       const response = await axios.post('http://localhost:8000/contact', fromdata);

//       if(response.status === 200){
//         toast.success(response.data.message);
//       }
//       setFromdata({
//         name:'',
//         email:'',
//         phone:'',
//         message:''
//       })

//     } catch (error) {
    
//       // Improved error handling
//       if (error.response) {
//         // Server-side error
//         toast.error(error.response.data.message || 'Something went wrong');
//       } else if (error.request) {
//         // Request was made but no response received
//         toast.error('No response from server');
//       } else {
//         // Other errors (e.g., network errors)
//         toast.error('An error occurred: ' + error.message);
//       }
//     }
//     finally{
//       setLoading(false);
//     }
//     } 
//   }

//   // Initialize AOS when the component mounts
//   useEffect(() => {
//     AOS.init({
//       duration: 1000,  // Adjust the duration as needed
//       easing: 'ease-out',
//       once: true  // Ensures animation runs only once
//     });
//   }, []);

//   return (
//     <>
//       <Navbar />

//       <div className="services-header" data-aos="fade-down">
//         <div className="header-content">
//           {/* <h1 className="header-title">Contact Us</h1> */}
//           <h1 className="display-4">Contact Us</h1>
//           <div className="breadcrumb mx-5">
//             <Link to="/" className="breadcrumb-link">Home</Link>
//             <span className="breadcrumb-separator"> &gt; </span>
//             {/* <span className="breadcrumb-current">Contact us</span> */}
//             <Link to="/contact" className="breadcrumb-link active">Contact Us</Link>
//           </div>
//         </div>
//       </div>

//       <div className="container my-5 contact-tab">
//         <div className="row justify-content-center shadow">
//           {/* Contact Form */}
//           <div className="col-lg-6 p-4 bg-white  contact-form">
//             <h3 className="contact-title mb-4" style={{ color: "#002D65" }}>Get in Touch</h3>

//             <form method='POST' action='' onSubmit={handleContactForm}>
//               <div className="form-group mb-3">
//                 <input type="text" name='name' value={fromdata.name} onChange={(e) => setFromdata({ ...fromdata, name: e.target.value })} className="form-control" placeholder="Name" />
//               </div>
//               <div className="form-group mb-3">
//                 <input type="email" name='email' value={fromdata.email} onChange={(e) => setFromdata({ ...fromdata, email: e.target.value })} className="form-control" placeholder="Email" />
//               </div>
//               <div className="form-group mb-3">
//                 <input type="tel" name='phone' value={fromdata.phone} onChange={(e) => setFromdata({ ...fromdata, [e.target.name]: e.target.value })} className="form-control" placeholder="Phone" />
//               </div>
//               <div className="form-group mb-3">
//                 <textarea className="form-control" value={fromdata.message} onChange={(e) => setFromdata({ ...fromdata, message: e.target.value })} rows="4" placeholder="Message"></textarea>
//               </div>
//               <div className="text-start">
//                 <button type="submit" className="btn submit-btn text-white" style={{ backgroundColor: "#002D65" }}>{isloading ? 'Processing....' : 'SEND MESSAGE'} </button>
//               </div>
//             </form>
//           </div>

//           {/* Contact Details */}
//           <div className="col-lg-4 p-4 mt-2 contact-info">
//             <div className="info-card border d-flex align-items-center mb-3">
//               <FaMapMarkerAlt className="info-icon" />
//               <div>
//                 <h5 className="info-title">Post Address</h5>
//                 <p>10/C Main, Raiwind Rd, Block C Nawab Town Lahore, Pakistan</p>
//               </div>
//             </div>
//             <div className="info-card border d-flex align-items-center mb-3">
//               <FaPhoneAlt className="info-icon fs-5" />
//               <div className='mx-auto'>
//                 <h5 className="info-title text-center">General Enquiries</h5>
//                 <p>Email: <Link to="mailto:techsol@gmail.com">techsol@gmail.com</Link></p>
//               </div>
//             </div>
//             <div className="info-card border d-flex align-items-center mb-3">
//               <FaClock className="info-icon" />
//               <div>
//                 <h5 className="info-title">Operation Hours</h5>
//                 <p>Mon–Friday: 10:00 to 18:00 (Saturday & Sunday: Closed)</p>
//               </div>
//             </div>
//             <div className="social-icons d-flex justify-content-center mt-4">
//               <Link to="#" className="social-link"><FaFacebook /></Link>
//               <Link to="#" className="social-link"><FaTwitter /></Link>
//               <Link to="#" className="social-link"><FaLinkedin /></Link>
//               <Link to="#" className="social-link"><FaInstagram /></Link>
//             </div>
//           </div>
//         </div>

//         {/* Google Map */}
//         <div className="row justify-content-center mt-5">
//           <div className="col-lg-12">
//             <div className="map-container shadow">
//               <iframe
//                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.301757816866!2d-122.41941528468294!3d37.77492977975851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085809c5ff0e2db%3A0x4e8ac2a8b1788f56!2s121%20Rock%20St%2C%20San%20Francisco%2C%20CA%2094111%2C%20USA!5e0!3m2!1sen!2sus!4v1639581442163!5m2!1sen!2sus"
//                 width="100%"
//                 height="400"
//                 style={{ border: 0 }}
//                 allowFullScreen=""
//                 loading="lazy"
//                 title="Company Location"
//               ></iframe>
//             </div>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default ContactTab;






// NEw .code

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/ContactTab.css';
import { FaMapMarkerAlt, FaPhoneAlt, FaClock, FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ContactTab = () => {
  const phoneRegex = /^[0-9]{11}$/; // Matches exactly 11 digits
  const [fromdata, setFromdata] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [nameError, setNameError] = useState(''); // State to hold name error message
  const [isloading, setLoading] = useState(false);

  const handleContactForm = async (e) => {
    e.preventDefault(); // Prevent the form from submitting and refreshing the page

    if (!fromdata.name || !fromdata.email || !fromdata.phone || !fromdata.message) {
      toast.error('All fields are required');
      return;
    }

    // Validate phone number (must be 11 digits and an integer)
    else if (!phoneRegex.test(fromdata.phone)) {
      toast.error('Phone number must be an integer with exactly 11 digits');
      return;
    }

    // If name contains numbers or special characters, show error and prevent form submission
    else if (/[0-9!@#$%^&*(),.?":{}|<>]/.test(fromdata.name)) {
      setNameError('Name must only contain letters and spaces');
      return;
    }

    else{
      // If all validations pass, submit the form
    setLoading(true);
    try {
      console.log(fromdata);
      const response = await axios.post('https://techsol-seven.vercel.app/api/contact', fromdata);

      if (response.status === 200) {
        toast.success(response.data.message);
      }

      setFromdata({
        name: '',
        email: '',
        phone: '',
        message: ''
      });

    } catch (error) {
      // Improved error handling
      if (error.response) {
        // Server-side error
        toast.error(error.response.data.message || 'Something went wrong');
      } else if (error.request) {
        // Request was made but no response received
        toast.error('No response from server');
      } else {
        // Other errors (e.g., network errors)
        toast.error('An error occurred: ' + error.message);
      }
    } finally {
      setLoading(false);
    }
    }
    
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setFromdata({ ...fromdata, name: value });

    // Check if name contains numbers or special characters
    if (/[0-9!@#$%^&*(),.?":{}|<>]/.test(value)) {
      setNameError('Name must only contain letters and spaces');
    } else {
      setNameError('');
    }
  };

  // Initialize AOS when the component mounts
  useEffect(() => {
    AOS.init({
      duration: 1000,  // Adjust the duration as needed
      easing: 'ease-out',
      once: true  // Ensures animation runs only once
    });
  }, []);


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default enter key behavior (form submission)
      // Trigger form submission here
      handleContactForm(event); // Or manually trigger submission
    }
  };
  

  return (
    <>
    <Helmet>
        <title>Contact Us | TechSol Technologies</title>
        <meta name="description" content="Get in touch with TechSol Technologies for inquiries, support, or feedback." />
      </Helmet>
      <Navbar />

      <div className="services-header" data-aos="fade-down">
        <div className="header-content">
          <h1 className="display-4" style={{fontWeight:'400'}}>Contact</h1>
          <div className="breadcrumb mx-5">
            <Link to="/" className="breadcrumb-link" style={{color:'#002D65' , fontSize:'1.1rem'}}>Home</Link>
            <span className="breadcrumb-separator"> &gt; </span>
            <Link to="/contact" className="breadcrumb-link active" style={{fontSize:'1.1rem'}}>Contact</Link>
          </div>
        </div>
      </div>

      <div className="container my-5 contact-tab">
        <div className="row justify-content-center shadow">
          <div className="col-lg-6 p-4 bg-white contact-form">
            <h3 className="contact-title mb-4" style={{ color: "#002D65" }}>Get in Touch</h3>

            <form method="POST" action="/submit-form" onSubmit={handleContactForm} onKeyDown={handleKeyDown}>

              <div className="form-group mb-3">
                <input
                  type="text"
                  name="name"
                  value={fromdata.name}
                  onChange={handleNameChange}
                  className="form-control"
                  placeholder="Name"
                />
                {nameError && <small className="text-danger d-flex justify-content-start">{nameError}</small>}
              </div>
              <div className="form-group mb-3">
                <input
                  type="email"
                  name="email"
                  value={fromdata.email}
                  onChange={(e) => setFromdata({ ...fromdata, email: e.target.value })}
                  className="form-control"
                  placeholder="Email"
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="tel"
                  name="phone"
                  value={fromdata.phone}
                  onChange={(e) => setFromdata({ ...fromdata, [e.target.name]: e.target.value })}
                  className="form-control"
                  placeholder="Phone"
                />
              </div>
              <div className="form-group mb-3">
                <textarea
                  className="form-control"
                  value={fromdata.message}
                  onChange={(e) => setFromdata({ ...fromdata, message: e.target.value })}
                  rows="4"
                  placeholder="Message"
                />
              </div>
              <div className="text-start">
                <button type="submit" className="btn submit-btn text-white" style={{ backgroundColor: "#002D65" }}>
                  {isloading ? 'Processing....' : 'SEND MESSAGE'}
                </button>
              </div>
            </form>
          </div>

          <div className="col-lg-4 p-4 mt-2 contact-info">
            <div className="info-card border d-flex align-items-center mb-3">
              <FaMapMarkerAlt className="info-icon" />
              <div>
                <h5 className="info-title">Post Address</h5>
                <p>10/C Main, Raiwind Rd, Block C Nawab Town Lahore, Pakistan</p>
              </div>
            </div>
            <div className="info-card border d-flex align-items-center mb-3">
              <FaPhoneAlt className="info-icon fs-5" />
              <div className="mx-auto">
                <h5 className="info-title text-center">General Enquiries</h5>
                <p>Email: <Link to="mailto:jointechsol@gmail.com" style={{ color: "#002D65" , fontWeight:'bold'}}>jointechsol@gmail.com</Link></p>
              </div>
            </div>
            <div className="info-card border d-flex align-items-center mb-3">
              <FaClock className="info-icon" />
              <div>
                <h5 className="info-title">Operation Hours</h5>
                <p>Mon–Friday: 9 am to 5 pm (Saturday & Sunday: <span style={{ color: "#b7333c" , fontWeight:'bold'}}>Closed</span>)</p>
              </div>
            </div>
            <div className="social-icons d-flex justify-content-center mt-4">
              <Link to="https://www.facebook.com/profile.php?id=61571234807734&mibextid=ZbWKwL" className="social-link"><FaFacebook style={{ color: "#002D65" }} /></Link>
              <Link to="https://www.linkedin.com/company/techsoltech/" className="social-link"><FaLinkedin style={{ color: "#002D65" }} /></Link>
              <Link to="https://www.instagram.com/techsol_technologies?igsh=OHZuNG51bWh3Mnlw" className="social-link"><FaInstagram style={{ color: "#002D65" }} /></Link>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-lg-12">
            <div className="map-container shadow">
              <iframe
                src="https://www.google.com/maps/embed?pb=..."
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Company Location"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ContactTab;


