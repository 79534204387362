import React, { useEffect } from 'react';
import '../assets/css/LandingPage.css';
import { Link } from 'react-router-dom';
import { IoIosArrowDroprightCircle } from "react-icons/io";
import bgVideotwo from '../assets/videos/bgVedioTwo.mp4';

import oneVideo from '../assets/videos/bgVedioTwo.mp4';


const LandingPage = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/@dotlottie/player-component@2.7.12/dist/dotlottie-player.mjs';
    script.type = 'module';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="hero-container">
      {/* Background Video */}
      <div className="video-background">
        <video autoPlay loop muted>
          <source src={oneVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="video-overlay"></div>

      <div className="d-flex main-div">
        {/* Text Section */}
        <div>
          <h1 className="hero-heading" style={{textAlign: 'left'}}>Your Vision Our Code Limitless Possibilities</h1>
          <p style={{textAlign: 'left'}}>
  Transforming your ideas into cutting-edge software solutions that drive growth.
  Unlock endless opportunities through innovation and expertise.
</p>

          <Link to="/services" className="text-decoration-none">
            <button className="find-solution-button" >
              Find Solutions <span className="arrow"><IoIosArrowDroprightCircle /></span>
            </button>
          </Link>
        </div>

        {/* Lottie Animation Section */}
        <div className="dotlottie-player-container">
          <dotlottie-player
            src="https://lottie.host/4cd49772-f9d7-44f4-8775-886848031c5c/jdVmVm7kHl.json"
            background="transparent"
            speed="1"
            style={{
              width: '100%',
              height: 'auto',
              maxWidth: '500px',
              maxHeight: '400px',
            }}
            loop
            autoplay
          ></dotlottie-player>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
