import React from 'react';
import { FaLaptopCode, FaClock, FaShieldAlt, FaCloud } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/StorySection.css'; // Add your custom styles here
import image from '../assets/images/lets-get.jpeg'

const StorySection = () => {
  const navigate = useNavigate();

  return (
    <div className="story-section-container py-5">
      <div className="container">
        <div className="row align-items-center">
          {/* Image Section */}
          <div className="col-lg-6 text-center">
            <img
              src={image} // Replace with your image URL
              alt="story"
              className="img-fluid rounded"
            />
          </div>

          {/* Content Section */}
          <div className="col-lg-6 text-start">
            <h1 className="mb-3 whatwedo-heading">What we do</h1>
            <p className="mb-4 whatwedo-para">
            We specialize in creating custom software solutions that empower your business. From responsive websites and mobile apps to AI-powered systems and innovative designs, we turn your challenges into opportunities. Let’s work together to bring your digital vision to life!
            </p>
            <button className="btn btn-learn text-white" style={{backgroundColor: '#002D65'}} onClick={()=>navigate('/services')}>Learn More</button>
          </div>
        </div>

        {/* Features Section */}
        <div className="row mt-5 text-center">
          <div className="col-md-3">
            <FaLaptopCode size={30} style={{color: '#133E87'}} className="mb-3" />
            <p>Custom Software Development</p>
          </div>
          <div className="col-md-3">
            <FaCloud size={30} style={{color: '#133E87'}} className="mb-3" />
            <p>Cloud Solutions</p>
          </div>
          <div className="col-md-3">
            <FaShieldAlt size={30} style={{color: '#133E87'}} className="mb-3" />
            <p>IT Security</p>
          </div>
          <div className="col-md-3">
            <FaClock size={30} style={{color: '#133E87'}} className="mb-3" />
            <p>24/7 Support</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorySection;
